import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { useCanvasTimer } from '../../../hooks/editor/useCanvasTimer'
import { useCanvas } from '../../../hooks/useCanvas'
import useDynamicRefs from '../../../hooks/useDynamicRefs'
import useObject from '../../../hooks/useObject'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { clearSelectedAnimationsAction } from '../../../store/slices/activeAnimation.slice'
import { clearAllSelectedObjectsAction } from '../../../store/slices/activeObject.slice'
import { updateMasterTimeline } from '../../../store/slices/masterTimeline.slice'
import { selectObjects, selectSortedRootObjects } from '../../../store/slices/objects.slice'
import CanvasItem from './CanvasItem'
import ObjectsWrapper from './ObjectsWrapper'

interface Props {
    size: {
        width: number
        height: number
    }
    zoomScale: number
}

const Canvas = ({ size, zoomScale }: Props) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { canvasResolution: canvas } = useCanvas()
    const sortedObjects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )
    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)

    const editor = useAppSelector((state) => state.editor)
    const canvasOverflowOn = editor.value.settings.canvasOverflowOn
    const canvasGridOn = editor.value.settings.canvasGridOn

    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)
    const [canvasScaleCoef, setCanvasScaleCoef] = useState<number>(1)
    const [, setRef] = useDynamicRefs<HTMLDivElement>()
    const { findObjectById } = useObject()

    const borderCoef: number = 2 * (1 / (canvasScaleCoef * zoomScale)) // 2px = default border size

    useLayoutEffect(() => {
        setCanvasScaleCoef(size.width / canvas.width)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size.width])

    const { setActiveObjectId } = useObjectActions()

    //unselect objects and animation when click into canvas
    const handleClick = () => {
        setActiveObjectId('', [])
        dispatch(clearAllSelectedObjectsAction())
        dispatch(clearSelectedAnimationsAction())
    }

    // TODO: Is it necessary to update timeline on every render?
    useEffect(() => {
        updateMasterTimeline(timeToSeek)
    }, [timeToSeek])

    const objects = useAppSelector((state) => selectObjects(selectUndoable(state).objects))
    useCanvasTimer(objects)

    //return object of
    //objectInWrapper: every root objects which include childId found in selectedObjectIds and
    //objectOutWrapper: every root objects which NOT include childId found in selectedObjectIds
    const objectsWhenWrapperExist = useMemo(() => {
        if (selectedObjectIds.length > 1) {
            let objectInWrapper: AnySceneObjectT[] = []
            selectedObjectIds.map((id) => {
                let object = findObjectById(id)
                if (object) return objectInWrapper.push(object)
                else return objectInWrapper
            })
            return {
                objectInWrapper: objectInWrapper,
                objectOutWrapper: sortedObjects.filter((x) =>
                    selectedObjectIds.find((y) => y === x.id) ? false : true
                ),
            }
        }
    }, [findObjectById, selectedObjectIds, sortedObjects])

    return (
        <Box
            id="editor-step-2"
            style={{
                width: canvas.width + 'px',
                height: canvas.height + 'px',
                transform: 'scale(' + canvasScaleCoef + ')',
                transformOrigin: 'top left',
                overflow: canvasOverflowOn ? 'visible' : 'hidden',
                outline: `${borderCoef}px solid ${theme.palette.divider}`,
                backgroundImage: canvasGridOn
                    ? 'linear-gradient(to right, rgba(40, 40, 40, 0.95), rgba(40, 40, 40, 0.95)),linear-gradient(to top, black 50%, white 50%),linear-gradient(to right, black 50%, white 50%)'
                    : '',
                backgroundSize: '40px 40px',
                backgroundPosition: 'center',
                backgroundBlendMode: 'normal, difference, normal',
            }}
        >
            <div
                className="canvas"
                id="canvas"
                ref={setRef('canvas')}
                style={{
                    width: canvas.width + 'px',
                    height: canvas.height + 'px',
                    transformOrigin: 'top left',
                    overflow: canvasOverflowOn ? 'visible' : 'hidden',
                    outline: `${borderCoef}px solid ${theme.palette.divider}`,
                    backgroundColor: 'rgba(0,0,0,0)',
                }}
                onClick={handleClick}
            >
                {/* when wrapping exist then divide all objects in canvas to two groups,
                first who cares about objects out of wrapper,
                and second who cares about object in wrapper, include child object under rootObjects  */}
                {objectsWhenWrapperExist ? (
                    <>
                        {/*objects out of ObjectsWrapper*/}
                        {objectsWhenWrapperExist.objectOutWrapper.map(
                            (object: AnySceneObjectT, index: number) => (
                                <CanvasItem
                                    key={index}
                                    object={object}
                                    canvasScaleCoef={canvasScaleCoef}
                                    isHiding={false}
                                    isLocking={false}
                                    borderCoef={borderCoef}
                                />
                            )
                        )}
                        {/* draggable wrapper for every object in selectedObjectIds*/}
                        <ObjectsWrapper
                            borderCoef={borderCoef}
                            wrapperObjects={objectsWhenWrapperExist.objectInWrapper}
                            canvasScaleCoef={canvasScaleCoef}
                        />
                    </>
                ) : (
                    // casual render objects when single select object
                    sortedObjects.map((object: AnySceneObjectT, index: number) => (
                        <CanvasItem
                            key={index}
                            object={object}
                            canvasScaleCoef={canvasScaleCoef}
                            isHiding={false}
                            isLocking={false}
                            borderCoef={borderCoef}
                        />
                    ))
                )}
            </div>
        </Box>
    )
}

export default Canvas
