import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { UpdateOffsetAnimationsT } from './animationTypes'
import { durationAnimationBaseAction } from './durationAnimationBaseAction'

export const durationAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationsT>
) => {
    const offset = action.payload.offset
    const selectedAnimations = action.payload.selectedAnimations

    selectedAnimations.forEach((animation) => {
        durationAnimationBaseAction(state, {
            payload: { animation, offset, objectId: animation.objectId },
            type: action.type,
        })
    })
}
