import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded'
import PublicOffRoundedIcon from '@mui/icons-material/PublicOffRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'

import { Box, Button, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateToString } from '../../helpers/date.helpers'
import { useOnScreen } from '../../hooks/useOnScreen'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { downloadFileAsync, getFileAsync } from '../../store/slices/file.slice'
import {
    duplicateGraphicAsync,
    exportGraphicAsync,
    getGraphicAsync,
    setFinalGraphicAsync,
} from '../../store/slices/graphic.slice'

import { Collections, ElectricalServices, Image } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { FaFont } from 'react-icons/fa'

import { EMPTY_IMAGE_SRC } from '../../helpers/object.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useCompanyStats } from '../../hooks/useCompanyStats'
import { useToast } from '../../hooks/useToast'
import { useVerifyPermission } from '../../hooks/useVerifyPermission'
import { getCompanyStatsAsync } from '../../store/slices/companyStats.slice'
import {
    GraphicAsset,
    getGraphicAfterIsFinal,
    getGraphicsAfterExport,
} from '../../store/slices/graphics.slice'
import { getThumbnailFileAsync } from '../../store/slices/thumbnailMap.slice'
import SkeletonGraphicTile from '../GraphicCommon/SkeletonGraphicTile'
import UnPublishDialog from '../GraphicCommon/UnPublishDialog'
import AlertDialog from '../common/AlertDialog'
import ButtonWithTooltip from '../common/ButtonWithTooltip'
import MoreContextMenu, { Item } from '../common/ContextMenu/MoreContextMenu'
import LoadingBackdrop from '../common/LoadingBackdrop'
import GraphicPublishDetail from './GraphicPublishDetail'

export const getIcon = (type: string) => {
    switch (type) {
        case 'font':
            return <FaFont />
        case 'json':
        case 'xml':
        case 'text':
            return <ElectricalServices />
        case 'image':
            return <Image />
        case 'sequence':
            return <Collections />
        default:
            return
    }
}

interface Props {
    graph: GraphicAsset
    onDelGraphic: (graphic: GraphicAsset) => void
    handleOpenDetail: (graphic: GraphicAsset) => void
    setFirstPage: () => void
}

function Graphic({ graph, onDelGraphic, handleOpenDetail, setFirstPage }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user.value)
    const { canDuplicate, canDelete, canPublishUnPublish, canExport } = useVerifyPermission(
        graph,
        graph.company
    )
    const dispatch = useAppDispatch()
    const { success } = useToast()

    const [openPublishDetail, setOpenPublishDetail] = useState(false)
    const [openUnPublishDetail, setOpenUnPublishDetail] = useState(false)
    const [notFinalYet, setNotFinalYet] = useState<boolean>(false)
    const [openDelAlert, setOpenDelAlert] = useState(false)
    const ref: any = useRef<HTMLDivElement>()
    const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-300px')
    const thumbnailMap = useAppSelector((state) => state.thumbnailMap)
    const tooltipPermission = graph.userId !== user._id ? t('graphics:noPermission') : undefined

    const fileContent = useAppSelector((state) => state.file.data)
    const { exported: exportsInCompany } = useCompanyStats({ id: graph.companyId })

    const thumbnailSrc = useMemo(() => {
        if (!graph.type) {
            return graph.url ?? thumbnailMap.data[graph.thumbnailId!]?.src
        } else if (['image', 'sequence'].includes(graph.type)) {
            return graph.url
        } else {
            return undefined
        }
    }, [graph, thumbnailMap.data])

    useEffect(() => {
        if (graph.thumbnailId && thumbnailSrc === undefined) {
            dispatch(getThumbnailFileAsync(graph.thumbnailId))
        }
    }, [dispatch, graph.thumbnailId, onScreen, thumbnailSrc])

    const prepareDetailData = async () => {
        await dispatch(getGraphicAsync(graph._id))
        if (graph.fileId) await dispatch(getFileAsync(graph.fileId))
    }

    const handleOpenPublishDetail = () => {
        if (graph.isFinal === false) {
            setNotFinalYet(true)
        } else {
            prepareDetailData()
            setOpenPublishDetail(true)
        }
    }
    const handleFinishAndPublish = async () => {
        dispatch(setFinalGraphicAsync(graph._id, { isFinal: true }))
        dispatch(
            getGraphicAfterIsFinal({
                id: graph._id,
                isFinal: true,
            })
        )

        await prepareDetailData()

        setNotFinalYet(false)
        setOpenPublishDetail(true)
    }
    const handleClosePublishDetail = async () => {
        setOpenPublishDetail(false)
    }

    const handleOpenUnPublishDetail = async () => {
        await dispatch(getGraphicAsync(graph._id))
        setOpenUnPublishDetail(true)
    }

    const handleCloseUnPublishDetail = () => {
        setOpenUnPublishDetail(false)
    }

    const handleOpenDelAlert = () => {
        setOpenDelAlert(true)
    }
    const onDelete = () => {
        setOpenDelAlert(false)
        onDelGraphic(graph)
    }

    const handleDuplicate = async () => {
        await dispatch(duplicateGraphicAsync(graph._id))
        setFirstPage()
    }

    const handleAfterExport = () => {
        dispatch(getCompanyStatsAsync(graph.companyId, true))
        dispatch(getGraphicsAfterExport({ id: graph._id }))
    }

    const handleExportHtml = async () => {
        if (!graph.isExported) {
            dispatch(
                exportGraphicAsync(
                    graph._id,
                    graph.company?.isPrivate
                        ? t('graphics:infoMessage.onlyTwoExportsFromPrivate')
                        : undefined,
                    handleAfterExport
                )
            )
        }
        graph.fileId && dispatch(downloadFileAsync(graph.fileId, graph.name))
        success('graphics:successMessage.graphicExported')
    }

    const menu: Item[] = [
        {
            icon: <DeleteRoundedIcon />,
            title: t('graphics:delete'),
            onClick: handleOpenDelAlert,
            disabled: Boolean(graph.type) && !canDelete,
            tooltipTitle: tooltipPermission,
        },
    ]

    if (!graph.type) {
        menu.unshift(
            ...[
                {
                    icon: <ContentCopyRoundedIcon />,
                    title: t('graphics:duplicate'),
                    onClick: handleDuplicate,
                    divider: true,
                    disabled: !canDuplicate,
                },
                {
                    icon: graph.isPublished ? <PublicOffRoundedIcon /> : <PublicRoundedIcon />,
                    title: graph.isPublished
                        ? t('graphics:unPublish')
                        : t('graphics:publishDetail'),
                    onClick: graph.isPublished
                        ? handleOpenUnPublishDetail
                        : handleOpenPublishDetail,
                    disabled: !canPublishUnPublish,
                    tooltipTitle: tooltipPermission,
                },
                {
                    icon: <FileDownloadRoundedIcon />,
                    title: t('graphics:exportHtml'),
                    onClick: handleExportHtml,
                    disabled: !canExport,
                    divider: true,
                },
            ]
        )
    }

    useEffect(() => {
        if (graph.type === 'font') {
            const fontStyle = document.createElement('style')
            fontStyle.appendChild(
                document.createTextNode(`
                    @font-face {
                        font-family: ${graph.name};
                        src: url(${graph.url});
                    }
                `)
            )

            document.head.appendChild(fontStyle)
        }
    }, [graph])

    return (
        <>
            <Card
                ref={ref}
                sx={{
                    width: 350,
                    backgroundColor: theme.palette.background.paper,
                    padding: 1,
                    borderRadius: 1,
                    minHeight: 100,
                    margin: 1.25,
                    position: 'relative',
                }}
            >
                {graph.type && (
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                borderTop: `60px solid ${theme.palette.grey[500]}`,
                                borderLeft: '60px solid transparent',
                            }}
                        ></Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                            }}
                        >
                            {getIcon(graph.type)}
                        </Box>
                    </>
                )}

                <CardContent>
                    {!thumbnailSrc && graph?.thumbnailId ? (
                        <SkeletonGraphicTile />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: theme.palette.grey[900],
                                height: 170,
                            }}
                        >
                            {graph.type === 'font' ? (
                                <Box sx={{ fontFamily: graph.name }}>{graph.name}</Box>
                            ) : graph.type && ['json', 'xml', 'text'].includes(graph.type) ? (
                                <Box sx={{ fontFamily: 'code' }}>{`${t(
                                    'graphics:dataSourceTile'
                                )} (${graph.type})`}</Box>
                            ) : (
                                <Box
                                    component="img"
                                    src={thumbnailSrc ?? EMPTY_IMAGE_SRC}
                                    sx={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        opacity: 0.7,
                                        p: 0,
                                    }}
                                />
                            )}
                        </Box>
                    )}
                    <Grid container mt={1}>
                        <Grid item xs={11}>
                            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                                {graph.name}
                            </Typography>
                            <Typography variant="body2">{graph.company?.name}</Typography>
                            <Typography variant="body2">
                                {graph.updated && dateToString(graph.updated)}
                                {' | '}
                                <i>
                                    {!graph.user?.firstName && !graph.user?.lastName
                                        ? t('graphics:anonymous')
                                        : ''}
                                </i>
                                {getFullNameString(graph.user?.firstName, graph.user?.lastName)}
                            </Typography>
                            <Typography variant="body2">
                                {graph.size && `${Math.round(graph.size / 1000)} KB`}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <MoreContextMenu data={menu} />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        mt={1}
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Grid container>
                                {graph.isFinal && (
                                    <Grid item pr={1}>
                                        <Tooltip title={t('graphics:isFinal')}>
                                            <CheckCircleRoundedIcon />
                                        </Tooltip>
                                    </Grid>
                                )}
                                {graph.isPublished && (
                                    <Grid item pr={1}>
                                        <Tooltip title={t('graphics:isPublished')}>
                                            <PublicRoundedIcon />
                                        </Tooltip>
                                    </Grid>
                                )}
                                {graph.isExported && (
                                    <Grid item pr={1}>
                                        <Tooltip
                                            title={
                                                graph.company?.isPrivate
                                                    ? t('graphics:infoTooltip.privateExported', {
                                                          exportsInCompany: exportsInCompany,
                                                      })
                                                    : t('graphics:infoTooltip.exported')
                                            }
                                        >
                                            <FileDownloadRoundedIcon />
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item>
                            <ButtonWithTooltip
                                tooltipTitle={t('graphics:editButtonTooltip')}
                                buttonComponent={
                                    <Button
                                        aria-label="menu"
                                        size="large"
                                        variant="text"
                                        onClick={() => handleOpenDetail(graph)}
                                    >
                                        {t('graphics:editButton')}
                                    </Button>
                                }
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {openPublishDetail && (
                <GraphicPublishDetail
                    open={openPublishDetail}
                    fileContent={fileContent?.blob}
                    onClose={() => handleClosePublishDetail()}
                    setFirstPage={() => setFirstPage()}
                />
            )}
            <UnPublishDialog
                open={openUnPublishDetail}
                onClose={() => handleCloseUnPublishDetail()}
                setFirstPage={() => setFirstPage()}
            />
            <AlertDialog
                open={openDelAlert}
                title={`${t('graphics:delete')} '${graph.name}' ?`}
                content={t('graphics:deleteQuestion')}
                onDisagree={() => setOpenDelAlert(false)}
                onAgree={() => onDelete()}
                txtDisagree={t('disagree')}
                txtAgree={t('delete')}
                colorAgree="error"
            />
            <AlertDialog
                open={notFinalYet}
                title={t('graphics:finishDialog.title')}
                content={t('graphics:finishDialog.content')}
                onDisagree={() => setNotFinalYet(false)}
                onAgree={handleFinishAndPublish}
                txtDisagree={t('graphics:finishDialog.disagree')}
                txtAgree={t('graphics:finishDialog.agree')}
                colorAgree="primary"
            />
            <LoadingBackdrop open={!fileContent && openPublishDetail} />
        </>
    )
}

export default Graphic
