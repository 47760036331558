import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { UpdateOffsetAnimationsT } from './animationTypes'
import { compareAnimations } from './compareAnimations'
import { relativeDurationAnimationBaseAction } from './relativeDurationAnimationBaseAction'

export const relativeDurationAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationsT>
) => {
    const offset = action.payload.offset
    const selectedAnimations = action.payload.selectedAnimations
    selectedAnimations
        .slice()
        .sort(compareAnimations)
        .forEach((animation: AnimationI) => {
            if (animation) {
                relativeDurationAnimationBaseAction(state, {
                    payload: { animation, offset, objectId: animation.objectId },
                    type: action.type,
                })
            }
        })
}
