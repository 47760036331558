import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { animationNode } from './animationNode'
import { UpdateOffsetAnimationT } from './animationTypes'
import { compareAnimations } from './compareAnimations'

export const relativeDurationAnimationBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationT>
) => {
    const animation = action.payload.animation
    const offset = action.payload.offset
    const objectId = action.payload.objectId
    if (+animation.duration + +offset > 0) {
        if (+animation.duration + +offset > 0) {
            state.value = {
                ...state.value,
                [objectId]: {
                    ...state.value[objectId],
                    animations: {
                        ...state.value[objectId].animations,
                        [animation.id]: animationNode(
                            state.value[objectId].animations[animation.id] ?? ({} as AnimationI),
                            {
                                property: 'duration',
                                value: +animation.duration + +offset,
                            }
                        ),
                    },
                },
            }
            if (state.value[objectId].animations) {
                Object.values(state.value[objectId].animations)
                    .slice()
                    .sort(compareAnimations)
                    .forEach((anim) => {
                        if (
                            anim &&
                            anim!.objectId === animation.objectId &&
                            anim!.delay >= +animation.delay + +animation.duration &&
                            anim!.tween.name === animation.tween.name
                        ) {
                            if (+anim!.delay + +offset >= 0) {
                                state.value = {
                                    ...state.value,
                                    [objectId]: {
                                        ...state.value[objectId],
                                        animations: {
                                            ...state.value[objectId].animations,
                                            [anim!.id]: animationNode(
                                                state.value[objectId].animations[anim!.id] ??
                                                    ({} as AnimationI),
                                                {
                                                    property: 'delay',
                                                    value: +anim!.delay + +offset,
                                                }
                                            ),
                                        },
                                    },
                                }
                            }
                        }
                    })
            }
        }
    }
}
