import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
} from '../../store/slices/activeAnimation.slice'
import {
    addObjectToSelectedObjectsAction,
    clearAllSelectedObjectsAction,
    clearObjectFromSelectedObjectsAction,
} from '../../store/slices/activeObject.slice'
import { useAnimationActions } from '../useAnimationActions'
import { useObjectActions } from '../useObjectActions'
import { useAppDispatch, useAppSelector } from '../useRedux'
import useWrapSelectedObject from './useWrapSelectedObject'
import useWrapSelectedObjects from './useWrapSelectedObjects'

const useMultiselectObject = (object: AnySceneObjectT) => {
    const dispatch = useAppDispatch()
    const { setActiveAnimations, removeSelectedAnimations } = useAnimationActions()

    const { setActiveObjectId, removeActiveObjectId } = useObjectActions()

    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const isActiveObject = !!selectedObjectIds.find((x) => x === object?.id)

    const isSelectedObject = (id: string): boolean => {
        return (
            selectedObjectIds.findIndex((s: string) => {
                return s === id
            }) !== -1
        )
    }

    const { activeChildObjectIds } = useWrapSelectedObject()
    const { activeChildObjectsIds } = useWrapSelectedObjects()

    const handleMultiselectObject = (ctrlCmdKey: boolean) => {
        const animationIds = object.animations ? Object.keys(object.animations) : []
        if (!ctrlCmdKey) {
            // todo
            dispatch(clearSelectedAnimationsAction())
            dispatch(clearAllSelectedObjectsAction())
            setActiveObjectId(object.id, animationIds)
            setActiveAnimations(animationIds, object.id)
        } else if (!selectedObjectIds.find((id) => id === object.id)) {
            //prevent click on child when parent was selected
            if (
                !activeChildObjectIds.includes(object.id) &&
                !activeChildObjectsIds.includes(object.id)
            ) {
                dispatch(addObjectToSelectedObjectsAction(object))
                dispatch(addSelectedAnimationsAction(animationIds))
            }
        } else if (isSelectedObject(object.id)) {
            removeActiveObjectId(object)
            dispatch(clearObjectFromSelectedObjectsAction(object))
            removeSelectedAnimations(Object.keys(object.animations))
        } else {
            dispatch(clearSelectedAnimationsAction())
            dispatch(clearAllSelectedObjectsAction())
        }
    }

    return { handleMultiselectObject, isActiveObject }
}

export default useMultiselectObject
