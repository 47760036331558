import { ObjectsStateT } from '../../objects.slice'
import { selectAllAnimations } from './selectAllAnimations'

export const selectAnimationsByIds = (objects: ObjectsStateT, ids: string[]): AnimationI[] => {
    let animations: AnimationI[] = []
    const objectsData = Object.values(objects.value)
    const allAnimations = selectAllAnimations(objectsData)

    Object.values(ids).forEach((id: string) => {
        if (id) {
            allAnimations.forEach((x) => (x.id === id ? animations.push(x) : animations))
        }
    })
    return animations
}
