import { PayloadAction } from '@reduxjs/toolkit'
import {
    defaultImage,
    defaultItem,
    defaultText,
} from '../../../../data/defaults/objects.types.defaults'
import { ObjectsStateT } from '../../objects.slice'
import { PayloadObjectsAndAnimations } from './objectTypes'

export const defaultObject = (type: string): AnySceneObjectT => {
    return type === 'text' ? defaultText : type === 'image' ? defaultImage : defaultItem
}

export const uploadObjectsAndAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<PayloadObjectsAndAnimations>
) => {
    const objects: AnySceneObjectT[] = action.payload.objects
    const animations: AnimationI[] = action.payload.animations

    objects.forEach((object) => {
        const objectAnimations = animations.reduce((acc, animation) => {
            if (animation.objectId === object.id) {
                return {
                    ...acc,
                    [animation.id]: animation,
                }
            }
            return acc
        }, {} as Record<string, AnimationI | undefined>)

        state.value = {
            ...state.value,
            [object.id]: {
                ...object,
                animations: objectAnimations,
            },
        }
    })
}
