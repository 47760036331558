import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { errorHandler } from './errorHandler'
import activeAnimationReducer from './slices/activeAnimation.slice'
import activeObjectReducer from './slices/activeObject.slice'
import archivedGraphicsReducer from './slices/archivedGraphics.slice'
import assetsReducer from './slices/assets.slice'
import canvasReducer from './slices/canvas.slice'
import canvasCursorReducer from './slices/canvasCursor.slice'
import companiesReducer from './slices/companies.slice'
import companiesStatsReducer from './slices/companiesStats.slice'
import companyReducer from './slices/company.slice'
import companyStatsReducer from './slices/companyStats.slice'
import editorReducer from './slices/editor.slice'
import enumsReducer from './slices/enums.slice'
import errorReducer from './slices/error.slice'
import fileReducer from './slices/file.slice'
import graphicReducer from './slices/graphic.slice'
import graphicsReducer from './slices/graphics.slice'
import graphicStatusReducer from './slices/graphicStatus.slice'
import loadingReducer from './slices/loading.slice'
import masterTimelineReducer from './slices/masterTimeline.slice'
import sessionReducer from './slices/session.slice'
import similarGraphicsReducer from './slices/similarGraphics.slice'
import thumbnailReducer from './slices/thumbnail.slice'
import thumbnailMapReducer from './slices/thumbnailMap.slice'
import timelineReducer from './slices/timeline.slice'
import timelinePreferencesReducer from './slices/timelinePreferences.slice'
import transactionsReducer from './slices/transactions.slice'
import transactionStatsReducer from './slices/transactionStats.slice'
import userReducer from './slices/user.slice'
import usersReducer from './slices/users.slice'
import undoableReducer from './undoableReduxer'

export const store = configureStore({
    reducer: {
        archivedGraphics: archivedGraphicsReducer,
        assets: assetsReducer,
        canvas: canvasReducer,
        masterTimeline: masterTimelineReducer,
        session: sessionReducer,
        error: errorReducer,
        users: usersReducer,
        companies: companiesReducer,
        companiesStats: companiesStatsReducer,
        company: companyReducer,
        enums: enumsReducer,
        user: userReducer,
        editor: editorReducer,
        undoable: undoableReducer,
        file: fileReducer,
        thumbnailMap: thumbnailMapReducer,
        similarGraphics: similarGraphicsReducer,
        graphic: graphicReducer,
        graphics: graphicsReducer,
        graphicStatus: graphicStatusReducer,
        thumbnail: thumbnailReducer,
        transactions: transactionsReducer,
        transactionStats: transactionStatsReducer,
        companyStats: companyStatsReducer,
        activeObject: activeObjectReducer,
        activeAnimation: activeAnimationReducer,
        canvasCursor: canvasCursorReducer,
        timeline: timelineReducer,
        timelinePreferences: timelinePreferencesReducer,
        loading: loadingReducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // We can disable serializable check in Serializability Middleware (persist state not used).
            // For ignoring only specific actions, see:
            // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
            serializableCheck: false,
        }).prepend(errorHandler.middleware),
})

export type AppDispatchT = typeof store.dispatch
export type AppStateT = ReturnType<typeof store.getState>
export type AppThunkT<ReturnType = void> = ThunkAction<
    ReturnType,
    AppStateT,
    unknown,
    Action<string>
>

export default store
