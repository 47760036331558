import { useTranslation } from 'react-i18next'
import { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom'
import ReactRouterPrompt from 'react-router-prompt'
import AlertDialog from './AlertDialog'

interface Props {
    title: string
    content?: string
    when: boolean | BlockerFunction
    txtAgree: string
}

const Prompt = ({ title, content, when, txtAgree }: Props) => {
    const { t } = useTranslation()

    return (
        <ReactRouterPrompt when={when}>
            {({ isActive, onConfirm, onCancel }) => {
                return (
                    <AlertDialog
                        title={title}
                        content={content}
                        open={isActive}
                        onDisagree={onCancel}
                        onAgree={onConfirm}
                        txtDisagree={t('disagree')}
                        txtAgree={txtAgree}
                    />
                )
            }}
        </ReactRouterPrompt>
    )
}

export default Prompt
