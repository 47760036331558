import LockIcon from '@mui/icons-material/Lock'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOff'
import { useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { t } from 'i18next'
import useMultiselectObject from '../../../../hooks/editor/useMultiselectObject'
import useWrapSelectedObject from '../../../../hooks/editor/useWrapSelectedObject'
import useWrapSelectedObjects from '../../../../hooks/editor/useWrapSelectedObjects'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { TIMELINE_ROW } from '../../../../style/sizing'
import ObjectTitle from '../../ObjectsTree/ObjectTitle'
import TimelineTimeStepWrapper from './TimelineRow/TimelineTimeStepWrapper'
import TimelineTweenWrapper from './TimelineRow/TimelineTweenWrapper'

interface Props {
    object: AnySceneObjectT
    timelineRowWidth: number
    animationId: string | null
    isMainRow: boolean
    isHidingObject: boolean
    isLockingObject: boolean
}

const TimelineRow = ({
    object,
    animationId,
    isMainRow,
    timelineRowWidth,
    isHidingObject,
    isLockingObject,
}: Props) => {
    const theme = useTheme()
    const { masterTimelineDuration } = useTimelineActions()
    const duration: number = masterTimelineDuration
    const tweensPerSec: number = 1
    const timeStepsDensity: number =
        Math.ceil(duration * tweensPerSec) > 0 ? Math.ceil(duration * tweensPerSec) : 1
    const tweenWidth: number = duration > 0 ? timelineRowWidth / duration : timelineRowWidth // width per sec
    const timeStepWidth: number = tweenWidth / tweensPerSec - 2
    const timeStepX: number = tweenWidth / tweensPerSec

    const { handleMultiselectObject, isActiveObject } = useMultiselectObject(object)
    const { activeChildObjectIds } = useWrapSelectedObject()
    const { activeChildObjectsIds } = useWrapSelectedObjects()

    const handleSelectObject = (ctrlCmdKey: boolean) => {
        handleMultiselectObject(ctrlCmdKey)
    }

    return (
        <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid
                container
                direction="row"
                alignItems="center"
                sx={{
                    zIndex: 50,
                    paddingLeft: '10px',
                    left: '0px',
                    position: 'sticky',
                    width: TIMELINE_ROW.headingWidth + 'px',
                    height: TIMELINE_ROW.height + 'px',
                    marginBottom: TIMELINE_ROW.paddingBottom + 'px',
                    background: theme.palette.grey[800],
                }}
            >
                <Grid
                    item
                    md={8}
                    sx={{
                        left: '10px',
                        // position: 'absolute',
                    }}
                >
                    <ObjectTitle
                        title={isMainRow ? object.title : ''}
                        isActive={
                            isActiveObject ||
                            activeChildObjectIds.includes(object.id) ||
                            activeChildObjectsIds.includes(object.id)
                        }
                        handleSelectObject={handleSelectObject}
                    />
                </Grid>
                <Grid item md={1} mr={1}>
                    {isLockingObject && isMainRow ? (
                        <Tooltip title={t('editor:locked')}>
                            <span>
                                <IconButton disabled={true}>
                                    <LockIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </Grid>
                <Grid item md={1}>
                    {isHidingObject && isMainRow ? (
                        <Tooltip title={t('editor:hidden')}>
                            <span>
                                <IconButton disabled={true}>
                                    <VisibilityOffRoundedIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>

            {/* tweeny | animation  */}
            <Grid
                sx={{
                    position: 'relative',
                    width: timelineRowWidth + 'px', //100%
                    height: TIMELINE_ROW.height + 'px',
                    overflow: 'visible',
                }}
            >
                {[...Array(timeStepsDensity)].map((_, index) => {
                    return (
                        <TimelineTimeStepWrapper
                            objectId={object.id}
                            key={index}
                            index={index}
                            TimelineTimeStepWidth={
                                [...Array(timeStepsDensity)].length - index === 0
                                    ? tweenWidth
                                    : timeStepWidth
                            }
                            timelineStepX={timeStepX}
                        />
                    )
                })}
                {!animationId &&
                    Object.keys(object.animations).map((animationId: string, index: number) => {
                        return (
                            <TimelineTweenWrapper
                                key={index}
                                index={index}
                                objectId={object.id}
                                animationId={animationId}
                                TimelineTweenWidth={tweenWidth}
                            />
                        )
                    })}
                {animationId && (
                    <TimelineTweenWrapper
                        index={0}
                        objectId={object.id}
                        animationId={animationId}
                        TimelineTweenWidth={tweenWidth}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default TimelineRow
