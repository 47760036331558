import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { t } from 'i18next'
import * as React from 'react'
import { forwardRef } from 'react'
import {
    AnimationInitCodeTrigger,
    AnimationUpdateCodeTrigger,
} from '../../../../../data/defaults/codetriggers.types.defaults'
import { selectUndoable } from '../../../../../helpers/selector.helpers'
import useMultiselectAnimation from '../../../../../hooks/editor/useMultiselectAnimation'
import { useAnimationActions } from '../../../../../hooks/useAnimationActions'
import useObject from '../../../../../hooks/useObject'
import { useAppSelector } from '../../../../../hooks/useRedux'

import { selectAnimationById } from '../../../../../store/slices/objects.slice/animation/selectAnimationById'
import { selectAnimationsByIds } from '../../../../../store/slices/objects.slice/animation/selectAnimationsByIds'
import { TIMELINE_ROW, TIMELINE_TWEEN } from '../../../../../style/sizing'
import RightClickContextMenu from '../../../../common/ContextMenu/RightClickContextMenu'
import CodeTriggerEditor from '../../CodeTriggerEditor'

interface Props {
    objectId: string
    animationId: string
    TimelineTweenWidth: number
    index: number
}

const TimelineTween = forwardRef<HTMLDivElement, Props>(
    ({ objectId, animationId, TimelineTweenWidth, index }, ref) => {
        const theme = useTheme()
        const timelineZoom = useAppSelector((state) => state.timelinePreferences.value.timelineZoom)
        const objects = useAppSelector((state) => selectUndoable(state).objects)
        const animation: AnimationI = selectAnimationById(Object.values(objects.value), animationId)

        const selectedAnimationIds: string[] = useAppSelector(
            (state) => state.activeAnimation.selected
        )
        const isActiveTween: boolean =
            selectedAnimationIds && selectedAnimationIds.includes(animationId) ? true : false

        const isSelectedTween = (id: string): boolean => {
            return (
                selectedAnimationIds.findIndex((s: string) => {
                    return s === id
                }) !== -1
            )
        }
        const getSelectedAnimations = (): AnimationI[] => {
            return selectAnimationsByIds(objects, selectedAnimationIds)
        }

        const [editorIsOpen, openEditor] = React.useState<boolean>(false)
        const [editorCode, setEditorCode] = React.useState<CodeTriggerT | null>(null)

        const handleCloseEditor = () => {
            openEditor(false)
        }
        const handleSaveAnimationInitCode = (newCode: CodeTriggerT) => {
            if (newCode.scope !== 'animation') return
            if (newCode.type === 'init') {
                updateAnimation(animation.id, animation.objectId, 'onInitCode', newCode)
            }
        }
        const handleSaveAnimationUpdateCode = (newCode: CodeTriggerT) => {
            if (newCode.scope !== 'animation') return
            if (newCode.type === 'time') {
                updateAnimation(animation.id, animation.objectId, 'onUpdateCode', newCode)
            }
        }
        const handleEditAnimationInitCode = () => {
            setEditorCode(animation.onInitCode ? animation.onInitCode : AnimationInitCodeTrigger)
            openEditor(true)
        }
        const handleEditAnimationUpdateCode = () => {
            setEditorCode(
                animation.onUpdateCode ? animation.onUpdateCode : AnimationUpdateCodeTrigger
            )
            openEditor(true)
        }
        const handleSaveAnimationCode = (newCode: CodeTriggerT) => {
            if (newCode.scope !== 'animation') return
            if (newCode.type === 'init') handleSaveAnimationInitCode(newCode)
            else if (newCode.type === 'time') handleSaveAnimationUpdateCode(newCode)
        }

        const { addNewAnimation, duplicateAnimations, deleteAnimations, updateAnimation } =
            useAnimationActions()

        const { handleMultiselectAnimations } = useMultiselectAnimation(animationId)

        const { setActiveAnimations } = useAnimationActions()
        const { findObjectById } = useObject()
        const handleSelectAnimationBetweenIndexes = () => {
            const object = findObjectById(objectId)
            const arrayAnimationIds = object ? Object.keys(object.animations) : []
            let result: string[] = []
            if (!!(arrayAnimationIds && arrayAnimationIds.length >= 1)) {
                const lastAnimId =
                    selectedAnimationIds.length === 1
                        ? selectedAnimationIds[0]
                        : selectedAnimationIds[selectedAnimationIds.length - 1]

                const lastIndex: number = arrayAnimationIds?.findIndex(
                    (animId) => animId === lastAnimId
                )
                const actualIndex: number = arrayAnimationIds?.findIndex(
                    (animId) => animId === animationId
                )
                if (actualIndex < lastIndex) {
                    result = arrayAnimationIds.slice(actualIndex, lastIndex)
                } else if (actualIndex > lastIndex) {
                    result = arrayAnimationIds.slice(lastIndex, actualIndex)
                }
            }
            setActiveAnimations(result, objectId)
        }
        /**
         * handler for selected animation | tween in row
         * @param ctrlKey - CTRL + click add selected animation to animationIds
         * @param event
         * @param shiftKey - SHIFT or MetaKey on iOS + click select every animation between last selected animation and current selected animation
         */
        const handleSelectTween = (ctrlKey: boolean, event: any, shiftKey: boolean) => {
            event.preventDefault()
            if (!event) event = window.event
            event.cancelBubble = true
            if (event.stopPropagation) event.stopPropagation()
            if (shiftKey) {
                handleSelectAnimationBetweenIndexes()

                //selected or unselect current clicked animation
                handleMultiselectAnimations(shiftKey)
            } else {
                handleMultiselectAnimations(ctrlKey)
            }
        }

        const handleCreateTween = () => addNewAnimation(objectId, Number(animation?.delay))

        const handleDuplicateAnimation = (event: any) => {
            event.preventDefault()
            if (!event) event = window.event
            event.cancelBubble = true
            if (event.stopPropagation) event.stopPropagation()

            duplicateAnimations(getSelectedAnimations())
        }

        const handleDeleteAnimations = (event: any) => {
            event.preventDefault()
            if (!event) event = window.event
            event.cancelBubble = true
            if (event.stopPropagation) event.stopPropagation()

            deleteAnimations(getSelectedAnimations())
        }

        const tweenStyleByType = (): { background: string; margin: number; color: string } => {
            const markWidth: number = 15
            let background: string = theme.palette.grey[50]
            let color: string = isActiveTween
                ? theme.palette.primary.main
                : isSelectedTween(animation.id!)
                ? theme.palette.primary.main
                : theme.palette.grey[600]
            let margin: number = 0

            if (animation) {
                const highlightColor = isSelectedTween(animation.id)
                    ? theme.palette.primary.main
                    : theme.palette.grey[500]
                if (animation.tween.type === 'set') {
                    background = highlightColor
                    margin = markWidth
                    color = isActiveTween
                        ? theme.palette.grey[600]
                        : isSelectedTween(animation.id!)
                        ? theme.palette.grey[600]
                        : theme.palette.grey[50]
                }
                if (animation.tween.type === 'to') {
                    background = `linear-gradient(90deg, ${theme.palette.grey[50]} ${
                        TimelineTweenWidth * Number(animation?.duration) - markWidth
                    }px, ${highlightColor} ${
                        TimelineTweenWidth * Number(animation?.duration) - markWidth
                    }px)`
                    margin = markWidth
                }
                if (animation.tween.type === 'from') {
                    background = `linear-gradient(90deg, ${highlightColor} ${markWidth}px, ${theme.palette.grey[50]} ${markWidth}px)`
                    margin = markWidth
                }
                if (animation.tween.type === 'fromTo') {
                    background = `linear-gradient(90deg, ${highlightColor} ${markWidth}px, ${
                        theme.palette.grey[50]
                    } ${markWidth}px, ${theme.palette.grey[50]} ${
                        TimelineTweenWidth * Number(animation?.duration) - markWidth
                    }px, ${highlightColor} ${
                        TimelineTweenWidth * Number(animation?.duration) - markWidth
                    }px)`
                    margin = markWidth
                }
            }
            return {
                background,
                margin,
                color,
            }
        }

        return (
            <Grid
                ref={ref}
                container
                alignItems="center"
                onClick={(e) => handleSelectTween(e.ctrlKey || e.metaKey, e, e.shiftKey)}
                sx={{
                    position: 'absolute',
                    width:
                        animation.tween.name === 'sequence' && timelineZoom < 80
                            ? '1px'
                            : TimelineTweenWidth * Number(animation?.duration) - 2 + 'px',
                    height: TIMELINE_TWEEN.height - 6 * TIMELINE_ROW.paddingBottom + 'px',
                    left: TimelineTweenWidth * Number(animation?.delay) + 'px',
                    backgroundColor: theme.palette.grey[50],
                    background: tweenStyleByType().background,
                    opacity: isSelectedTween(animation.id) ? 1 : 0.5,
                    color: tweenStyleByType().color,
                    borderRadius: TIMELINE_TWEEN.borderRadius,
                    cursor: 'pointer',
                    margin: TIMELINE_ROW.paddingBottom * 3 + 'px 2px',
                }}
            >
                <RightClickContextMenu
                    menuLabel={
                        <Tooltip
                            title={
                                animation.tween.name !== 'sequence'
                                    ? animation?.tween.name + ' (' + animation?.title + ')'
                                    : animation?.title
                            }
                        >
                            <Grid
                                sx={{
                                    position: 'relative',
                                    width:
                                        TimelineTweenWidth * Number(animation?.duration) - 2 + 'px',
                                    height: '100%',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    noWrap={true}
                                    fontWeight="normal"
                                    sx={{
                                        margin:
                                            animation.tween.name !== 'sequence'
                                                ? `0 calc(0.25rem + ${tweenStyleByType().margin}px)`
                                                : undefined,
                                        textAlign:
                                            animation.tween.name !== 'sequence'
                                                ? undefined
                                                : 'center',
                                    }}
                                >
                                    {animation.tween.name !== 'sequence' ? (
                                        animation?.tween.name + ' (' + animation?.title + ')'
                                    ) : (
                                        <p></p> //can not use (null || '' || undefined) because then right click not work
                                    )}
                                </Typography>
                            </Grid>
                        </Tooltip>
                    }
                    data={
                        selectedAnimationIds.length <= 1
                            ? animation?.tween.name === 'sequence'
                                ? [
                                      {
                                          icon: <DeleteRoundedIcon />,
                                          title: t('editor:timeline.animationActions.delete'),
                                          onClick: handleDeleteAnimations,
                                          divider: false,
                                      },
                                  ]
                                : [
                                      {
                                          icon: <AddRoundedIcon />,
                                          title: t('editor:timeline.animationActions.addAnimation'),
                                          onClick: handleCreateTween,
                                          divider: true,
                                      },
                                      {
                                          icon: <ContentCopyIcon />,
                                          title: t('editor:timeline.animationActions.duplicate'),
                                          onClick: handleDuplicateAnimation,
                                          divider: true,
                                      },
                                      {
                                          icon: <DataObjectRoundedIcon />,
                                          title: t('editor:timeline.animationActions.editInitCode'),
                                          onClick: () => handleEditAnimationInitCode(),
                                          divider: false,
                                      },
                                      {
                                          icon: <DataObjectRoundedIcon />,
                                          title: t(
                                              'editor:timeline.animationActions.editUpdateCode'
                                          ),
                                          onClick: () => handleEditAnimationUpdateCode(),
                                          divider: true,
                                      },
                                      {
                                          icon: <DeleteRoundedIcon />,
                                          title: t('editor:timeline.animationActions.delete'),
                                          onClick: handleDeleteAnimations,
                                          divider: false,
                                      },
                                  ]
                            : animation?.tween.name === 'sequence'
                            ? [
                                  {
                                      icon: <DeleteRoundedIcon />,
                                      title: t('editor:timeline.animationActions.deleteSelected'),
                                      onClick: handleDeleteAnimations,
                                      divider: false,
                                  },
                              ]
                            : [
                                  {
                                      icon: <ContentCopyIcon />,
                                      title: t(
                                          'editor:timeline.animationActions.duplicateSelected'
                                      ),
                                      onClick: handleDuplicateAnimation,
                                      divider: true,
                                  },
                                  {
                                      icon: <DeleteRoundedIcon />,
                                      title: t('editor:timeline.animationActions.deleteSelected'),
                                      onClick: handleDeleteAnimations,
                                      divider: false,
                                  },
                              ]
                    }
                />

                <CodeTriggerEditor
                    open={editorIsOpen}
                    title="Code to be uploaded into the HTML/JS engine"
                    code={editorCode}
                    onSaveHandler={handleSaveAnimationCode}
                    onCloseHandler={handleCloseEditor}
                />
            </Grid>
        )
    }
)

export default TimelineTween
