import { PayloadAction } from '@reduxjs/toolkit'
import { AnimationsT, ObjectsStateT } from '../../objects.slice'

const deleteOneAnimation = (
    animations: Record<string, AnimationI | undefined>,
    animationId: string
) => {
    if (animations) delete animations[animationId]
    return animations
}

export const deleteAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AnimationsT>
) => {
    const animations = action.payload.animations
    animations.forEach((animation) => {
        const objectId = animation.objectId
        if (state.value[objectId]) {
            state.value = {
                ...state.value,
                [objectId]: {
                    ...state.value[objectId],
                    animations: deleteOneAnimation(
                        { ...state.value[objectId].animations },
                        animation.id
                    ),
                },
            }
        }
    })
}
