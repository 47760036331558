import { useMemo } from 'react'
import {
    ObjectStyles,
    getDistributionGap,
    getObjectPositioning,
    getResultMultiAlignment,
    getSortedObjectArray,
} from '../../../../helpers/object.helpers'
import useObject from '../../../../hooks/useObject'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../hooks/useTimelineActions'
import { updateObjectsDifferentStylesPropertyAction } from '../../../../store/slices/objects.slice'
import { UpdateObjectsDifferentStyles } from '../../../../store/slices/objects.slice/object/objectTypes'
import Alignment from './Alignment'

const MultiAlign = () => {
    const dispatch = useAppDispatch()
    const { refreshMasterTimeline } = useTimelineActions()

    const selectedObjectIds = useAppSelector((state) => state.activeObject.selected)
    const { findObjectById } = useObject()
    const handleAlign = (property: string, value: string, styles: ObjectStyles) => {
        let temporaryObjects: UpdateObjectsDifferentStyles[] = []
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)
            if (selectedObject) {
                temporaryObjects.push({
                    object: selectedObject,
                    property: property,
                    value: getResultMultiAlignment(selectedObject, value, styles),
                })
            }
        })
        dispatch(updateObjectsDifferentStylesPropertyAction(temporaryObjects))
    }

    const handleDistribution = (
        property: string,
        distributionGap: number,
        sortedObjects: AnySceneObjectT[],
        arrayTop: number[],
        arrayLeft: number[]
    ) => {
        let modifiedTop: number
        let modifiedLeft: number
        let temporaryObjects: UpdateObjectsDifferentStyles[] = []
        sortedObjects.forEach((object, index) => {
            const prevObjectStyles: ObjectStyles =
                index === 0
                    ? { top: 0, left: 0, width: 0, height: 0 }
                    : getObjectPositioning(sortedObjects[index - 1])
            const actualObjectStyles: ObjectStyles = getObjectPositioning(object)

            if (property === 'top') {
                if (
                    actualObjectStyles.top !== Number(Math.min(...arrayTop)) &&
                    actualObjectStyles.top !== Number(Math.max(...arrayTop))
                ) {
                    modifiedTop = !modifiedTop
                        ? Number(prevObjectStyles.top) +
                          Number(prevObjectStyles.height) +
                          Number(distributionGap)
                        : Number(modifiedTop) +
                          Number(prevObjectStyles.height) +
                          Number(distributionGap)

                    temporaryObjects.push({
                        object: object,
                        property: property,
                        value: modifiedTop,
                    })
                }
            }
            if (property === 'left') {
                if (
                    actualObjectStyles.left !== Number(Math.min(...arrayLeft)) &&
                    actualObjectStyles.left !== Number(Math.max(...arrayLeft))
                ) {
                    modifiedLeft = !modifiedLeft
                        ? Number(prevObjectStyles.left) +
                          Number(prevObjectStyles.width) +
                          Number(distributionGap)
                        : Number(modifiedLeft) +
                          Number(prevObjectStyles.width) +
                          Number(distributionGap)

                    temporaryObjects.push({
                        object: object,
                        property: property,
                        value: modifiedLeft,
                    })
                }
            }
        })
        dispatch(updateObjectsDifferentStylesPropertyAction(temporaryObjects))
    }
    /**
     * when selected objects do not have same parentId then disable multiAlignment action
     */
    const disabledMultiAlignment = useMemo(() => {
        let parentId: string | null | undefined = 'empty'
        let disabled: boolean = false
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)
            if (parentId === 'empty') {
                parentId = selectedObject?.parentId
            }
            if (parentId !== selectedObject?.parentId) {
                disabled = true
                parentId = 'empty'
            }
        })
        return disabled
    }, [findObjectById, selectedObjectIds])

    const findEdgeValues = (property: string, value: string) => {
        let arrayTop: number[] = []
        let arrayLeft: number[] = []
        let arrayEdgeRight: number[] = []
        let arrayEdgeBottom: number[] = []
        let heights: number[] = []
        let widths: number[] = []
        let selectedObjects: AnySceneObjectT[] = []
        selectedObjectIds.forEach((id) => {
            let selectedObject = findObjectById(id)

            if (selectedObject) {
                const values: ObjectStyles = getObjectPositioning(selectedObject)
                arrayTop = [...arrayTop, Number(values.top)]
                arrayLeft = [...arrayLeft, Number(values.left)]
                arrayEdgeRight = [...arrayEdgeRight, Number(values.left) + Number(values.width)]
                arrayEdgeBottom = [...arrayEdgeBottom, Number(values.top) + Number(values.height)]
                heights = [...heights, Number(values.height)]
                widths = [...widths, Number(values.width)]
                selectedObjects = [...selectedObjects, selectedObject]
            }
        })

        const wrapperObjectsStyles: ObjectStyles = {
            top: Number(Math.min(...arrayTop)),
            left: Number(Math.min(...arrayLeft)),
            width: Number(Math.max(...arrayEdgeRight) - Math.min(...arrayLeft)),
            height: Number(Math.max(...arrayEdgeBottom) - Math.min(...arrayTop)),
        }

        if (value === 'horizontallyDistribute') {
            const distributionHorizontalGap = getDistributionGap(
                Number(wrapperObjectsStyles.width),
                widths,
                Number(selectedObjectIds.length - 1)
            )

            const sortedSelectedObject = getSortedObjectArray(selectedObjects, 'left')
            handleDistribution(
                property,
                distributionHorizontalGap,
                sortedSelectedObject,
                arrayTop,
                arrayLeft
            )
        } else if (value === 'verticallyDistribute') {
            const distributionVerticalGap = getDistributionGap(
                Number(wrapperObjectsStyles.height),
                heights,
                Number(selectedObjectIds.length - 1)
            )

            const sortedSelectedObject = getSortedObjectArray(selectedObjects, 'top')
            handleDistribution(
                property,
                distributionVerticalGap,
                sortedSelectedObject,
                arrayTop,
                arrayLeft
            )
        } else {
            handleAlign(property, value, wrapperObjectsStyles)
        }
        setTimeout(() => refreshMasterTimeline(), 200)
    }

    return (
        <Alignment
            onClick={findEdgeValues}
            disabled={disabledMultiAlignment}
            multiAlign
            disabledDistribute={selectedObjectIds.length < 3}
        />
    )
}

export default MultiAlign
