import ReactSplit, { SplitDirection } from '@devbookhq/splitter'
import { Container, Grid, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { BrowserTypes, browserName, isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import LeftSideNavbar from '../components/Editor/LeftSideNavbar'
import Preview from '../components/Editor/Preview'
import RightSideNavbar from '../components/Editor/RightSideNavbar'
import TimelineNavbar from '../components/Editor/TimelineNavbar'
import { withErrorBoundary } from '../components/withErrorBoundary'
import useId from '../hooks/useId'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getGraphicAndProcessAsync } from '../store/slices/graphic.slice'

import { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom'
import CanvasSettingDialog from '../components/Editor/Preview/CanvasSettingDialog'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import LoadingBackdropWithLabel from '../components/common/LoadingBackdropWithLabel'
import Prompt from '../components/common/Prompt'
import TransitionAlerts from '../components/common/TransitionAlert'
import { Status } from '../constants/status'
import { selectCanUndo } from '../helpers/selector.helpers'
import { useCheckEditor } from '../hooks/useCheckEditor'
import useEditor from '../hooks/useEditor'
import {
    SettingsKey,
    clearAndReset,
    getPreferencesAsync,
    setOpenCanvasSettings,
    setPreferencesAsync,
} from '../store/slices/editor.slice'
import { getTimelinePreferencesAsync } from '../store/slices/timelinePreferences.slice'

const Editor = () => {
    const { t } = useTranslation()

    const canUndo = useAppSelector((state) => selectCanUndo(state))
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic)

    const preferences = useAppSelector((state) => state.editor.value.settings)
    const loading = useAppSelector((state) => state.loading.value)
    const percentLoading = useAppSelector((state) => state.loading.valuePercent)

    const { id, setId } = useId()

    useEffect(() => {
        if (id) {
            dispatch(getGraphicAndProcessAsync(id))
        } else {
            dispatch(getPreferencesAsync())
            dispatch(getTimelinePreferencesAsync())
            dispatch(clearAndReset())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    useEffect(() => {
        if (
            // no permission
            !graphic.data._id &&
            id &&
            graphic.status === Status.NO_GRAPHIC_PERMISSION
        ) {
            setId(null)
        }
    }, [graphic.data._id, id, graphic.status, setId])

    const { status } = useEditor(id)
    const { matchAssetsAndGraphicAssets } = useCheckEditor()
    const currentStep = useAppSelector((state) => state.editor.value.settings.currentStep)

    const handleResizeFinished = (newSizes: number[], preferencesType: SettingsKey) => {
        dispatch(setPreferencesAsync(preferencesType, newSizes))
    }

    const openCanvasSettings = useAppSelector((state) => state.editor.value.openCanvasSettings)
    const handleCloseCanvasDialog = () => {
        dispatch(setOpenCanvasSettings(false))
    }
    useEffect(() => {
        if (id === null && !currentStep.startsWith('editor')) {
            dispatch(setOpenCanvasSettings(true))
        } else {
            dispatch(setOpenCanvasSettings(false))
        }
    }, [currentStep, dispatch, id])

    const openTransitionAlert: boolean = useMemo(() => {
        return status && browserName !== BrowserTypes.Chrome ? true : false
    }, [status])

    const isDiscardContent = (canUndo || !matchAssetsAndGraphicAssets) && !openCanvasSettings
    const [promptMsg, setPromptMsg] = useState<string>('')
    const blockerFunction: BlockerFunction = (action: any) => {
        if (!canUndo && action.historyAction === 'POP') {
            setPromptMsg('backAction')
            return true
        } else {
            setPromptMsg('discard')
            return isDiscardContent
        }
    }

    if (isMobileOnly)
        return (
            <>
                <Container component="main" maxWidth="lg">
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="stretch"
                        sx={{ height: '90vh' }}
                    >
                        <Typography variant="h2">{t('editor:mobilePageHeader')}</Typography>
                        <Typography variant="h5" mt={2} mb={4}>
                            {t('editor:mobilePageInfo')}
                        </Typography>
                    </Grid>
                </Container>
            </>
        )
    else
        return (
            <>
                <Prompt
                    when={blockerFunction}
                    title={t(`editor:${promptMsg}Title`)}
                    content={t(`editor:${promptMsg}Content`)}
                    txtAgree={t('leave')}
                />
                <ReactSplit
                    direction={SplitDirection.Horizontal}
                    initialSizes={preferences.horizontalSplitterSize}
                    onResizeFinished={(_, newSizes) =>
                        handleResizeFinished(newSizes, 'horizontalSplitterSize')
                    }
                    gutterClassName="custom-gutter-horizontal"
                >
                    <ReactSplit
                        direction={SplitDirection.Vertical}
                        initialSizes={preferences.subVerticalSplitterSize}
                        onResizeFinished={(_, newSizes) =>
                            handleResizeFinished(newSizes, 'subVerticalSplitterSize')
                        }
                        gutterClassName="custom-gutter-vertical"
                    >
                        <ReactSplit
                            direction={SplitDirection.Horizontal}
                            initialSizes={preferences.subHorizontalSplitterSize}
                            onResizeFinished={(_, newSizes) =>
                                handleResizeFinished(newSizes, 'subHorizontalSplitterSize')
                            }
                            gutterClassName="custom-gutter-horizontal"
                        >
                            <LeftSideNavbar />
                            <Preview />
                        </ReactSplit>
                        <TimelineNavbar />
                    </ReactSplit>
                    <RightSideNavbar />
                </ReactSplit>
                <LoadingBackdrop open={!status} />
                <LoadingBackdropWithLabel open={loading} progress={percentLoading} />
                <CanvasSettingDialog
                    open={openCanvasSettings}
                    disabled={false}
                    hideCloseButton
                    onClose={handleCloseCanvasDialog}
                />
                {openTransitionAlert && (
                    <TransitionAlerts
                        severity="warning"
                        alertMessage={t('editor:notSupportedBrowser')}
                    />
                )}
            </>
        )
}

export default withErrorBoundary(Editor)
