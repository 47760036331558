import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, IconButton, ListItemAvatar, ListItemText, MenuItem, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFullNameString, getShortcutFromFullNameString } from '../../helpers/string.helpers'
import { useAppSelector } from '../../hooks/useRedux'

const ProfileMenuItem = () => {
    const user = useAppSelector((state) => state.user.value)

    const { t } = useTranslation()
    const theme = useTheme()

    const navigate = useNavigate()
    const location = useLocation()

    const handleNavigateToProfile = () => {
        navigate('/account/profile')
    }

    const handleLogOut = async (e: React.MouseEvent<HTMLElement>, route: string) => {
        if (e.stopPropagation) e.stopPropagation()

        if (location.pathname === route) {
            return
        }
        navigate(route)
    }

    return (
        <MenuItem onClick={handleNavigateToProfile}>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>
                    {getShortcutFromFullNameString(user.firstName, user.lastName).length > 0 ? (
                        getShortcutFromFullNameString(user.firstName, user.lastName)
                    ) : (
                        <PersonIcon />
                    )}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={getFullNameString(user.firstName, user.lastName)}
                secondary={user.email}
            />
            <Tooltip title={t('menu:logout')}>
                <IconButton edge="end" onClick={(e) => handleLogOut(e, '/logout')}>
                    <LogoutIcon />
                </IconButton>
            </Tooltip>
        </MenuItem>
    )
}
export default ProfileMenuItem
