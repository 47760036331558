import { selectUndoable } from '../../../helpers/selector.helpers'
import { useAppSelector } from '../../../hooks/useRedux'
import { useTimelineActions } from '../../../hooks/useTimelineActions'
import { selectSortedRootObjects } from '../../../store/slices/objects.slice'
import { calcTimelineRowWidth } from '../../../style/sizing'
import TimelineRows from './Timeline/TimelineRows'
interface Props {
    size: { width: number }
}

const Timeline = ({ size }: Props) => {
    const objects: AnySceneObjectT[] = useAppSelector((state) =>
        selectSortedRootObjects(selectUndoable(state).objects)
    )
    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const timelineZoom: number = timelinePreferences.timelineZoom

    const { masterTimelineDuration } = useTimelineActions()

    const timelineRowWidth: number = calcTimelineRowWidth(
        size.width,
        masterTimelineDuration,
        timelineZoom
    )

    return (
        <TimelineRows
            objects={objects}
            timelineRowWidth={timelineRowWidth}
            isHidingObject={false}
            isLockingObject={false}
        />
    )
}

export default Timeline
