import { selectUndoable } from '../../helpers/selector.helpers'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
} from '../../store/slices/activeAnimation.slice'
import {
    addObjectToSelectedObjectsAction,
    clearAllSelectedObjectsAction,
} from '../../store/slices/activeObject.slice'
import { selectObjectById } from '../../store/slices/objects.slice'
import { selectAnimationById } from '../../store/slices/objects.slice/animation/selectAnimationById'
import { AppStateT } from '../../store/store'
import { useAnimationActions } from '../useAnimationActions'
import { useAppDispatch, useAppSelector } from '../useRedux'
import useWrapSelectedObject from './useWrapSelectedObject'
import useWrapSelectedObjects from './useWrapSelectedObjects'

const useMultiselectAnimation = (animationId: string) => {
    const dispatch = useAppDispatch()
    const { setActiveAnimations, removeSelectedAnimations } = useAnimationActions()

    const selectedAnimationIds: string[] = useAppSelector((state) => state.activeAnimation.selected)

    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const animation: AnimationI = selectAnimationById(Object.values(objects.value), animationId)

    const object: AnySceneObjectT = useAppSelector((state: AppStateT) =>
        selectObjectById(selectUndoable(state).objects, animation.objectId)
    )

    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)
    const { activeChildObjectIds } = useWrapSelectedObject()
    const { activeChildObjectsIds } = useWrapSelectedObjects()

    const handleMultiselectAnimations = (ctrlShiftKey: boolean) => {
        if (!ctrlShiftKey) {
            dispatch(clearSelectedAnimationsAction())
            dispatch(clearAllSelectedObjectsAction())
            setActiveAnimations([animationId], animation.objectId)
        } else if (!selectedAnimationIds.find((id) => id === animationId)) {
            if (
                !selectedObjectIds.find((id) => id === animation.objectId) &&
                !activeChildObjectIds.includes(animation.objectId) &&
                !activeChildObjectsIds.includes(animation.objectId)
            ) {
                dispatch(addObjectToSelectedObjectsAction(object))
            }
            dispatch(addSelectedAnimationsAction([animationId]))
        } else {
            removeSelectedAnimations([animationId])
        }
    }
    return { handleMultiselectAnimations }
}

export default useMultiselectAnimation
