import { ObjectsStateT, selectObjectById, selectObjects, sortObjects } from '../../objects.slice'

export const checkIndexesBase = (state: ObjectsStateT, parentId: string | null) => {
    const objects = Object.values(state.value)
    //if no objects then return
    if (objects.length === 0) return

    let siblings: AnySceneObjectT[]

    if (parentId) {
        const parentChildIds: string[] = selectObjectById(state, parentId).childIds
        // if not indexes for control then return
        if (parentChildIds.length === 0) return
        // find all siblings from parent.childIds
        siblings = parentChildIds.map(
            (id) => selectObjects(state).filter((object) => object.id === id)[0]
        )
    } else {
        siblings = selectObjects(state).filter((object) => object.parentId === null)
    }

    // order siblings objects by index
    const orderedSiblings: AnySceneObjectT[] = sortObjects(siblings)

    // if their index is not equal to order, update its index
    orderedSiblings.forEach((sibling, index) => {
        if (sibling.index !== index) {
            state.value = {
                ...state.value,
                [sibling.id]: { ...state.value[sibling.id], index: index },
            }
        }
    })
}
