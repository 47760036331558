import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from './objectNodeUpdate'
import { UpdateObjectsDifferentStyles } from './objectTypes'

//this function is used direct for update position of objects when move wrapped object
export const updateObjectsPosition = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateObjectsDifferentStyles[]>
) => {
    const temporaryObjects = action.payload
    temporaryObjects.forEach((temp) => {
        const id = temp.object.id
        state.value = {
            ...state.value,
            [id]: objectNodeUpdate(state.value[id], {
                id: id,
                property: temp.property,
                value: temp.value,
            }),
        }
    })
}
