import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { memo, useMemo } from 'react'
import { selectUndoable } from '../../helpers/selector.helpers'
import { useAppSelector } from '../../hooks/useRedux'
import { useTimelineActions } from '../../hooks/useTimelineActions'
import { selectAllAnimations } from '../../store/slices/objects.slice/animation/selectAllAnimations'
import { TIMELINE_ROW, calcTimelineRowWidth } from '../../style/sizing'
import Timeline from './TimelineNavbar/Timeline'
import TimeStamps from './TimelineNavbar/Timeline/TimeStamps'
import TimelineControlTools from './TimelineNavbar/Timeline/TimelineControlTools'
import TimelineDevTools from './TimelineNavbar/Timeline/TimelineDevTools'

const TimelineNavbar = memo(() => {
    const timelinePreferences = useAppSelector((state) => state.timelinePreferences.value)
    const timelineZoom: number = timelinePreferences.timelineZoom

    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const animations: AnimationI[] = useMemo(() => {
        return selectAllAnimations(Object.values(objects.value))
    }, [objects])

    const { masterTimelineDuration } = useTimelineActions()

    const visibilityByAnimations = animations.length > 0 ? 'visible' : 'hidden'
    const visibilityByObjects =
        objects.value === null || Object.keys(objects.value).length === 0 ? 'hidden' : 'visible'
    const disabledByAnimations = animations.length > 0 ? false : true

    const width = 0
    const timelineRowWidth: number = useMemo(
        () => calcTimelineRowWidth(width, masterTimelineDuration, timelineZoom),
        [masterTimelineDuration, timelineZoom]
    )
    const timelineRowAreaWidth: number = TIMELINE_ROW.headingWidth
    const timelineAreaWidth = timelineRowWidth + timelineRowAreaWidth

    return (
        <Grid container direction="column" sx={{ height: '100%' }}>
            <TimelineControlTools
                visibility={visibilityByObjects}
                disabled={disabledByAnimations}
            />
            <Box
                className="custom-timeline-navbar"
                sx={{
                    flex: 1,
                    width: '100%',
                    overflow:
                        visibilityByObjects === 'hidden' || visibilityByAnimations === 'hidden'
                            ? 'hidden'
                            : 'scroll',
                }}
            >
                <TimeStamps width={timelineRowWidth} visibility={visibilityByAnimations} />
                <Box
                    sx={{
                        width: visibilityByObjects === 'hidden' ? '100%' : timelineAreaWidth + 'px',
                        flex: 1, // to fill remaining vertical space
                        overflowY: 'visible', //auto// vertically scrolable
                        overflowX: 'visible',
                    }}
                >
                    <Timeline size={{ width: timelineRowWidth }} />
                </Box>
            </Box>
            <TimelineDevTools visibility={visibilityByObjects} disabled={disabledByAnimations} />
        </Grid>
    )
})

export default TimelineNavbar
