import { combineReducers } from '@reduxjs/toolkit'
import undoable from 'redux-undo'
import objectsReducer from './slices/objects.slice'

const undoableReducer = undoable(
    combineReducers({
        objects: objectsReducer,
    })
)

export default undoableReducer
