import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QueryOptionsT } from '../../components/common/DataGrid'
import { Status } from '../../constants/status'
import { get } from '../../helpers/ajax.helpers'
import { AppThunkT } from '../store'
import { AssetT } from './assets.slice'
import { GraphicT } from './graphic.slice'

interface PublishPayload {
    id: string
    isPublished: boolean
}
interface IsFinalPayload {
    id: string
    isFinal: boolean
}

export interface AssetRef {
    id: string
}

export type GraphicAsset = GraphicT | AssetT

interface GraphicsStateT {
    data: GraphicAsset[]
    total: number
    status?: string
}

const initialState: GraphicsStateT = {
    data: [],
    total: 0,
    status: Status.OK,
}

export const graphicsSlice = createSlice({
    name: 'graphics',
    initialState,
    reducers: {
        getGraphicsPending: (state: GraphicsStateT) => {
            state.status = undefined
        },
        getGraphicsClearState: (state: GraphicsStateT) => {
            state.status = undefined
            state.data = initialState.data
            state.total = initialState.total
        },

        getGraphicsRejected: (state: GraphicsStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },

        getGraphicsFulfilled: (
            state: GraphicsStateT,
            action: PayloadAction<ListT<GraphicAsset>>
        ) => {
            state.data = action.payload.data
            state.total = action.payload.total
            state.status = Status.OK
        },
        getGraphicsAfterExport: (state: GraphicsStateT, action: PayloadAction<{ id: string }>) => {
            state.data = state.data.map((graphic) => {
                if (graphic._id === action.payload.id) {
                    return { ...graphic, isExported: true }
                } else {
                    return graphic
                }
            })
        },
        getGraphicsNextPageFulfilled: (
            state: GraphicsStateT,
            action: PayloadAction<ListT<GraphicAsset>>
        ) => {
            state.data = [...state.data, ...action.payload.data]
            state.total = action.payload.total
            state.status = Status.OK
        },
        getGraphicAfterPublishUnPublish: (
            state: GraphicsStateT,
            action: PayloadAction<PublishPayload>
        ) => {
            state.data = state.data.map((graphic) => {
                if (graphic._id === action.payload.id) {
                    return { ...graphic, isPublished: action.payload.isPublished }
                } else {
                    return graphic
                }
            })
        },
        getGraphicAfterIsFinal: (state: GraphicsStateT, action: PayloadAction<IsFinalPayload>) => {
            state.data = state.data.map((graphic) => {
                if (graphic._id === action.payload.id) {
                    return { ...graphic, isFinal: action.payload.isFinal }
                } else {
                    return graphic
                }
            })
        },
        getGraphicsAfterDelete: (state: GraphicsStateT, action: PayloadAction<{ id: string }>) => {
            state.data = state.data.filter((graphic) => graphic._id !== action.payload.id)
            state.total = state.total - 1
        },
    },
})

export const {
    getGraphicsFulfilled,
    getGraphicsNextPageFulfilled,
    getGraphicsAfterExport,
    getGraphicAfterPublishUnPublish,
    getGraphicAfterIsFinal,
    getGraphicsAfterDelete,
    getGraphicsPending,
    getGraphicsClearState,
    getGraphicsRejected,
} = graphicsSlice.actions

export const getGraphicsAsync =
    (queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicsPending())
            const graphics = await get<ListT<GraphicAsset>>('graphics', queryOptions)
            dispatch(getGraphicsFulfilled(graphics))
        } catch (error: any) {
            dispatch(getGraphicsRejected(error.message))
        }
    }
export const getGraphicsCurrentAsync =
    (queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicsPending())
            const graphics = await get<ListT<GraphicAsset>>('graphics/current', queryOptions)
            if (queryOptions?.skip === 0) {
                dispatch(getGraphicsFulfilled(graphics))
            } else {
                dispatch(getGraphicsNextPageFulfilled(graphics))
            }
        } catch (error: any) {
            dispatch(getGraphicsRejected(error.message))
        }
    }

export const getGraphicsPublishedAsync =
    (queryOptions: QueryOptionsT): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicsPending())
            const graphics = await get<ListT<GraphicAsset>>('graphics/published', queryOptions)
            if (queryOptions.skip === 0) {
                dispatch(getGraphicsFulfilled(graphics))
            } else {
                dispatch(getGraphicsNextPageFulfilled(graphics))
            }
        } catch (error: any) {
            dispatch(getGraphicsRejected(error.message))
        }
    }

export default graphicsSlice.reducer
