import { Box } from '@mui/material'
import { selectUndoable } from '../../../helpers/selector.helpers'
import { useAppSelector } from '../../../hooks/useRedux'
import { selectObjectById } from '../../../store/slices/objects.slice'
import { AppStateT } from '../../../store/store'
import BackgroundImage from '../../Editor/RightSideNavbar/ExpandableSections/BackgroundImage'

interface Props {
    styleProperty: GroupStyleI
    handleOnChange: (e: any) => void
    handleSetStyleValue: (property: any, value: any) => void
    handleSetAttributeValue: (property: any, value: any) => void
}

const ImageInput = ({
    styleProperty,
    handleOnChange,
    handleSetStyleValue,
    handleSetAttributeValue,
}: Props) => {
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const object: AnySceneObjectT = useAppSelector((state: AppStateT) =>
        selectObjectById(selectUndoable(state).objects, selectedObjectIds[0] ?? '')
    )

    if (!selectedObjectIds.length) return <></>

    if (object.type !== 'item' && object.type !== 'image') return <></>

    return (
        <Box>
            <BackgroundImage
                object={object.type === 'item' ? (object as ItemI) : (object as ImageI)}
                styleProperty={styleProperty}
                handleOnChange={handleOnChange}
                handleSetStyleValue={handleSetStyleValue}
                handleSetAttributeValue={handleSetAttributeValue}
            />
        </Box>
    )
}

export default ImageInput
