import DataObjectRoundedIcon from '@mui/icons-material/DataObjectRounded'
import {
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@mui/material'
import { useRef, useState } from 'react'
import {
    TimelineInitCodeTrigger,
    TimelineUpdateCodeTrigger,
} from '../../../../../data/defaults/codetriggers.types.defaults'
import { useAppSelector } from '../../../../../hooks/useRedux'
import { useTimelineActions } from '../../../../../hooks/useTimelineActions'
import ButtonWithTooltip from '../../../../common/ButtonWithTooltip'
import CodeTriggerEditor from '../../CodeTriggerEditor'

interface Props {
    disabled: boolean
}

const TimelineCodeTriggers = ({ disabled }: Props) => {
    const timeline: TimelineI = useAppSelector((state) => state.timeline.value)
    const { updateTimeline } = useTimelineActions()

    const [openEditorMenu, setOpenEditorMenu] = useState<boolean>(false)
    const [editorIsOpen, openEditor] = useState<boolean>(false)
    const [editorCode, setEditorCode] = useState<CodeTriggerT | null>(null)

    const anchorRef = useRef<HTMLButtonElement>(null)

    const timelineInitCode: CodeTriggerT = timeline.onInitCode
        ? timeline.onInitCode
        : TimelineInitCodeTrigger
    const timelineUpdateCode: CodeTriggerT = timeline.onUpdateCode
        ? timeline.onUpdateCode
        : TimelineUpdateCodeTrigger

    const handleToggleEditorMenu = () => {
        setOpenEditorMenu((prevOpen) => !prevOpen)
    }

    const handleCloseEditorMenu = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return

        setOpenEditorMenu(false)
    }

    function handleEditorListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpenEditorMenu(false)
        } else if (event.key === 'Escape') {
            setOpenEditorMenu(false)
        }
    }

    const handleEditOnInitCode = () => {
        setOpenEditorMenu(false)
        setEditorCode(timelineInitCode)
        openEditor(true)
    }

    const handleEditOnUpdateCode = () => {
        setOpenEditorMenu(false)
        setEditorCode(timelineUpdateCode)
        openEditor(true)
    }

    const handleCloseEditor = () => {
        setOpenEditorMenu(false)

        openEditor(false)
    }
    const handleSaveTimelineCode = (newCode: CodeTriggerT) => {
        if (newCode.scope !== 'timeline') return

        if (newCode.type === 'init') {
            updateTimeline('onInitCode', newCode)
        } else if (newCode.type === 'time') {
            updateTimeline('onUpdateCode', newCode)
        }
    }

    return (
        <>
            <ButtonWithTooltip
                tooltipTitle={'Timeline code triggers'}
                buttonComponent={
                    <IconButton
                        aria-label="menu"
                        size="large"
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={openEditorMenu ? 'composition-menu' : undefined}
                        aria-expanded={openEditorMenu ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggleEditorMenu}
                        disabled={disabled}
                    >
                        <DataObjectRoundedIcon fontSize="small" />
                    </IconButton>
                }
                disabled={false}
            />
            <Popper
                sx={{ zIndex: 100 }}
                open={openEditorMenu}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseEditorMenu}>
                                <MenuList
                                    autoFocusItem={openEditorMenu}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleEditorListKeyDown}
                                >
                                    <MenuItem key="editOnInitCode" onClick={handleEditOnInitCode}>
                                        <ListItemIcon>
                                            <DataObjectRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText>{'Timeline init code'}</ListItemText>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem
                                        key="editOnUpdateCode"
                                        onClick={handleEditOnUpdateCode}
                                    >
                                        <ListItemIcon>
                                            <DataObjectRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText>{'Timeline update code'}</ListItemText>
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <CodeTriggerEditor
                open={editorIsOpen}
                title="Code to be uploaded into the HTML/JS engine"
                code={editorCode}
                onSaveHandler={handleSaveTimelineCode}
                onCloseHandler={handleCloseEditor}
            />
        </>
    )
}
export default TimelineCodeTriggers
