import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StateT = {
    // value: string | null
    selected: string[]
}

const initialState: StateT = {
    // value: null,
    selected: [],
}

type PayloadT = {
    id: string
}

export const activeObjectSlice = createSlice({
    name: 'activeObject',
    initialState,
    reducers: {
        setActiveObjectAction: (state, action: PayloadAction<PayloadT>) => {
            state.selected = [action.payload.id]
        },
        addObjectToSelectedObjectsAction: (state, action: PayloadAction<PayloadT>) => {
            if (
                state.selected.findIndex((s: string) => {
                    return s === action.payload.id
                }) === -1
            ) {
                state.selected.push(action.payload.id)
            }
        },
        setSelectedObjectIdsAction: (state, action: PayloadAction<{ objectIds: string[] }>) => {
            state.selected = action.payload.objectIds
        },
        clearObjectFromSelectedObjectsAction: (state, action: PayloadAction<PayloadT>) => {
            var index: number
            if (
                (index = state.selected.findIndex((s: string) => {
                    return s === action.payload.id
                })) !== -1
            ) {
                state.selected.splice(index, 1)
            }
        },
        clearAllSelectedObjectsAction: (state) => {
            state.selected = []
        },
    },
})

export const {
    setActiveObjectAction,
    addObjectToSelectedObjectsAction,
    setSelectedObjectIdsAction,
    clearObjectFromSelectedObjectsAction,
    clearAllSelectedObjectsAction,
} = activeObjectSlice.actions

export default activeObjectSlice.reducer
