import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type StateT = {
    selected: string[]
}

const initialState: StateT = {
    selected: [],
}

type PayloadT = {
    id: string
}

export const activeAnimationSlice = createSlice({
    name: 'activeAnimation',
    initialState,
    reducers: {
        setActiveAnimationAction: (state, action: PayloadAction<PayloadT>) => {
            state.selected = [action.payload.id]
        },
        setSelectedAnimationsForObjectAction: (
            state,
            action: PayloadAction<{ animationsIds: string[] }>
        ) => {
            state.selected = action.payload.animationsIds
        },
        addSelectedAnimationsAction: (state, action: PayloadAction<string[]>) => {
            const set = new Set([...state.selected, ...action.payload])
            state.selected = Array.from(set)
        },
        removeSelectedAnimationsAction: (state, action: PayloadAction<string[]>) => {
            state.selected = state.selected.filter((id) => !action.payload.includes(id))
        },
        clearSelectedAnimationsAction: (state) => {
            state.selected = []
        },
    },
})

export const {
    setActiveAnimationAction,
    setSelectedAnimationsForObjectAction,
    addSelectedAnimationsAction,
    removeSelectedAnimationsAction,
    clearSelectedAnimationsAction,
} = activeAnimationSlice.actions

export default activeAnimationSlice.reducer
