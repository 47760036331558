import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionCreators } from 'redux-undo'
import * as yup from 'yup'
import config from '../../config'
import { Status } from '../../constants/status'
import { defaultCanvas } from '../../data/defaults/objects.types.defaults'
import { del, get, getFile, post, postFile, put, Storage } from '../../helpers/ajax.helpers'
import { S3_PREFIX } from '../../helpers/file.helpers'
import {
    duplicateGraphicName,
    findNextNumberVersion,
    getAssetTypeMsg,
} from '../../helpers/graphic.helpers'
import { generateObjectId } from '../../helpers/string.helpers'
import { useToast } from '../../hooks/useToast'
import { AppThunkT } from '../store'
import { ARCHIVED_QUERY_OPTIONS, getArchivedGraphicsAsync } from './archivedGraphics.slice'

import { AssetT } from './assets.slice'
import { getCompaniesFulfilled, getCompaniesPending } from './companies.slice'
import { CompanyT } from './company.slice'
import {
    clear,
    clearAndReset,
    setOpenCanvasSettings,
    setPreferencesFulfilled,
} from './editor.slice'
import { getFileFulfilled, getFilePending, getFileRejected, processFilePending } from './file.slice'
import { AssetRef, GraphicAsset } from './graphics.slice'
import { resetTmpIds } from './objects.slice'
import { SimilarGraphicT } from './similarGraphics.slice'
import { getThumbnailFulfilled } from './thumbnail.slice'
import { setTimelinePreferencesFulfilled } from './timelinePreferences.slice'
import { UserT } from './user.slice'

export const graphicSchema = yup
    .object({
        name: yup.string().required('graphics:nameRequired'),
        description: yup.string().max(255).nullable(true),
        _id: yup.string().max(25).nullable(true),
        companyId: yup.string().max(25).required('company:companyRequired'),
        price: yup
            .number()
            .transform((curr, orig) => (orig === '' ? 0 : curr))
            .integer('graphics:validationMessage.priceInteger')
            .max(5, 'graphics:validationMessage.priceMax')
            .min(0, 'graphics:validationMessage.priceMin')
            .nullable(true)
            .default(0),
    })
    .required()

export const graphicPriceSchema = yup
    .object({
        price: yup
            .number()
            .transform((curr, orig) => (orig === '' ? 0 : curr))
            .integer('graphics:validationMessage.priceInteger')
            .max(5, 'graphics:validationMessage.priceMax')
            .min(0, 'graphics:validationMessage.priceMin')
            .nullable(true)
            .default(0),
    })
    .required()

export type GraphicPriceT = yup.InferType<typeof graphicPriceSchema>

export interface GraphicT extends yup.InferType<typeof graphicSchema> {
    _id: string
    userId?: string
    companyId: string
    parentId?: string
    updatedById?: string
    updatedBy?: UserT | null
    fileId?: string
    thumbnailId?: string
    user?: UserT | null
    company?: CompanyT | null
    name: string
    description: string
    created: Date
    updated: Date
    tags?: string[]
    isFinal?: boolean
    price: number
    isPublished?: boolean
    revision?: number
    canvasSize?: number[]
    framerate?: number
    isExported?: boolean
    tmpId?: string
    assetsRef?: AssetRef[]
    url?: string
    type?: string
    size?: number
}

interface GraphicStateT {
    data: GraphicAsset
    status?: string
}

const getInitialStateData = (): GraphicAsset => ({
    _id: '',
    userId: '',
    companyId: '',
    updatedBy: null,
    updatedById: '',
    fileId: '',
    thumbnailId: undefined,
    user: null,
    company: null,
    name: '',
    description: '',
    created: new Date(),
    updated: new Date(),
    tags: [],
    isFinal: false,
    price: 0,
    isPublished: false,
    revision: 0,
    tmpId: generateObjectId(),
})

const initialState: GraphicStateT = {
    data: getInitialStateData(),
    status: Status.OK,
}

export const graphicSlice = createSlice({
    name: 'graphic',
    initialState,
    reducers: {
        getGraphicPending: (state: GraphicStateT) => {
            state.data = getInitialStateData()
            state.status = undefined
        },
        getGraphicFulfilled: (
            state: GraphicStateT,
            action: PayloadAction<GraphicT | undefined>
        ) => {
            state.status = Status.OK
            state.data = action.payload ?? getInitialStateData()
        },
        getGraphicFillActualData: (state: GraphicStateT, action: PayloadAction<GraphicT>) => {
            state.data = action.payload
        },
        getGraphicRejected: (state: GraphicStateT, action: PayloadAction<string>) => {
            state.status = action.payload
        },
    },
})

export const {
    getGraphicPending,
    getGraphicFulfilled,
    getGraphicFillActualData,
    getGraphicRejected,
} = graphicSlice.actions

export const getGraphicAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            const graphic = await get<GraphicT>(`graphics/${id}`)
            await dispatch(getThumbnailFulfilled({ id: graphic.thumbnailId }))
            dispatch(getGraphicFulfilled(graphic))
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }
export const getGraphicAndProcessAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(clear())
            dispatch(getFilePending())
            dispatch(getGraphicPending())
            const graphic = await get<GraphicT>(`graphics/${id}`)
            const file = await getFile(graphic.fileId!)
            await dispatch(getThumbnailFulfilled({ id: graphic.thumbnailId }))
            await dispatch(getGraphicFulfilled(graphic))
            if (graphic.canvasSize?.length === 2) {
                await dispatch(
                    setPreferencesFulfilled({
                        canvasResolution: [graphic.canvasSize[0], graphic.canvasSize[1]],
                    })
                )
            } else {
                await dispatch(
                    setPreferencesFulfilled({
                        canvasResolution: [defaultCanvas.width, defaultCanvas.height],
                    })
                )
            }

            if (graphic.framerate) {
                dispatch(setTimelinePreferencesFulfilled({ framerate: graphic.framerate }))
            } else {
                //if graphic do not have framerate then open canvas Settings dialog in editor
                dispatch(setOpenCanvasSettings(true))
            }
            await dispatch(processFilePending({ id: graphic.fileId!, blob: file }))
        } catch (error: any) {
            dispatch(
                getGraphicRejected(
                    error.statusCode === 403 ? Status.NO_GRAPHIC_PERMISSION : error.message
                )
            )
        }
    }

export const getAssetAsync =
    (id: string, onError?: (status: string) => void): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getFilePending())
            dispatch(getGraphicPending())
            const asset = await get<AssetT>(`assets/${id}`)
            dispatch(getGraphicFulfilled(asset))
            await dispatch(getFileFulfilled())
        } catch (error: any) {
            dispatch(
                getGraphicRejected(
                    error.statusCode === 403 ? Status.NO_ASSET_PERMISSION : error.message
                )
            )
            onError?.(error.message)
        }
    }

export const getGraphicAndFileAsync =
    (id: string, onError?: (status: string) => void): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(clear())
            dispatch(getFilePending())
            dispatch(getGraphicPending())
            const graphic = await get<GraphicT>(`graphics/${id}`)
            const file = await getFile(graphic.fileId!)

            await dispatch(getThumbnailFulfilled({ id: graphic.thumbnailId }))
            await dispatch(getGraphicFulfilled(graphic))
            if (graphic.canvasSize?.length === 2) {
                await dispatch(
                    setPreferencesFulfilled({
                        canvasResolution: [graphic.canvasSize[0], graphic.canvasSize[1]],
                    })
                )
            } else {
                await dispatch(
                    setPreferencesFulfilled({
                        canvasResolution: [defaultCanvas.width, defaultCanvas.height],
                    })
                )
            }

            if (graphic.framerate) {
                dispatch(setTimelinePreferencesFulfilled({ framerate: graphic.framerate }))
            } else {
                //if graphic do not have framerate then open canvas Settings dialog in editor
                dispatch(setOpenCanvasSettings(true))
            }
            await dispatch(getFileFulfilled({ id: graphic.fileId!, blob: file }))
        } catch (error: any) {
            dispatch(
                getGraphicRejected(
                    error.statusCode === 403 ? Status.NO_GRAPHIC_PERMISSION : error.message
                )
            )
            onError?.(error.message)
        }
    }

export const getGraphicVersionFileAsync =
    (fileId: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getFilePending())
            const file = await getFile(fileId)

            await dispatch(processFilePending({ id: fileId, blob: file }))
        } catch (error: any) {
            dispatch(getFileRejected(error.message))
        }
    }

export const duplicateGraphicAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            let graphic: GraphicT = await get(`graphics/${id}`)
            const companyId = graphic.companyId
            const regex = '^' + graphic.name.replace(/(\()(\d+)(\))$/, () => '')

            const similarGraphics = await get<SimilarGraphicT[]>(
                `graphics/similar?companyId=${companyId}&name=${regex}`
            )
            let finalNumber = findNextNumberVersion(similarGraphics)
            let newName = duplicateGraphicName(graphic, finalNumber)

            //to do problem with delete duplicate and thumbnailId
            const graphicData = await post<{ _id: string } & GraphicT>(`graphics/${id}/duplicate`, {
                name: newName,
            })

            dispatch(getThumbnailFulfilled({ id: graphicData?.thumbnailId }))
            dispatch(getGraphicFulfilled(graphicData))
            const { success } = useToast()
            success('graphics:successMessage.duplicateGraphic')
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export const updateGraphicAsync =
    (data: GraphicT, fileData: string | null, reset: boolean): AppThunkT =>
    async (dispatch, getState) => {
        try {
            dispatch(getGraphicPending())
            if (fileData) {
                const file = new File([fileData], 'graphics.html', { type: 'text/html' })
                const { _id } = await postFile(file, { storage: config.storage as Storage })
                data = { ...data, fileId: _id }
                dispatch(getFileFulfilled({ id: _id, blob: file }))
            }

            const res = (await put(`graphics/${data._id}`, data)) as GraphicT
            const graphic = {
                ...data,
                ...res,
            }
            dispatch(resetTmpIds())
            if (reset) {
                await dispatch(clearAndReset())
            } else {
                dispatch(getThumbnailFulfilled({ id: graphic.thumbnailId }))
                dispatch(getGraphicFulfilled(graphic))
                dispatch(ActionCreators.clearHistory())
            }
            if (graphic._id) dispatch(getArchivedGraphicsAsync(graphic._id, ARCHIVED_QUERY_OPTIONS))
            const { success } = useToast()
            success('graphics:successMessage.graphicAndFileSave')
        } catch (error: any) {
            await dispatch(getGraphicFillActualData(data))
            await dispatch(getGraphicRejected(error.message))
        }
    }

export const submitAssetAsync =
    (
        data: GraphicAsset,
        successMessage: string | null,
        onSubmit?: (id?: string) => void
    ): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())

            await put(`assets/${data._id}`, data)
            dispatch(getGraphicFulfilled(data))
            onSubmit?.(data._id)

            if (successMessage) {
                const { success } = useToast()
                success(successMessage)
            }
        } catch (error: any) {
            await dispatch(getGraphicRejected(error.message))
        }
    }

export const submitGraphicAsync =
    (
        data: GraphicT,
        file: string | null,
        successMessage: string | null,
        onSubmit?: (id?: string) => void
    ): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            if (file) {
                const fil = new File([file], 'graphics.html', { type: 'text/html' })
                const { _id } = await postFile(fil, { storage: config.storage as Storage })
                data = { ...data, fileId: _id }
                dispatch(getFileFulfilled({ id: _id, blob: fil }))
            }

            let graphic: GraphicT
            if (data._id) {
                const res = (await put(`graphics/${data._id}`, data)) as GraphicT
                graphic = {
                    ...data,
                    ...res,
                }
                onSubmit?.()
            } else {
                const { _id } = (await post(`graphics`, {
                    ...data,
                    _id: data.tmpId,
                })) as { _id: string }

                graphic = {
                    ...data,
                    _id,
                }
            }

            await dispatch(resetTmpIds())
            await dispatch(getThumbnailFulfilled({ id: graphic.thumbnailId }))
            await dispatch(getGraphicFulfilled(graphic))
            await dispatch(ActionCreators.clearHistory())
            if (!data._id && graphic._id) {
                onSubmit?.(graphic._id)
            }
            if (graphic._id) dispatch(getArchivedGraphicsAsync(graphic._id, ARCHIVED_QUERY_OPTIONS))
            if (successMessage) {
                const { success } = useToast()
                success(successMessage)
            }
        } catch (error: any) {
            await dispatch(getGraphicFillActualData(data))
            await dispatch(getGraphicRejected(error.message))
        }
    }

export const submitTutorialGraphicAsync =
    (data: GraphicT, fileData: string | null, successMessage: string | null): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            if (fileData) {
                const file = new File([fileData], 'graphics.html', { type: 'text/html' })
                const { _id } = await postFile(file, { storage: config.storage as Storage })
                data = { ...data, fileId: _id }
                dispatch(getFileFulfilled({ id: _id, blob: file }))
            }
            let graphic: GraphicT
            const companyId = data.companyId
            const regex = '^' + data.name.replace(/(\()(\d+)(\))$/, () => '')

            const similarGraphics = await get<SimilarGraphicT[]>(
                `graphics/similar?companyId=${companyId}&name=${regex}`
            )
            let finalNumber = findNextNumberVersion(similarGraphics)
            let newName = duplicateGraphicName(data, finalNumber)
            let newData = { ...data, name: newName }
            const { _id } = (await post(`graphics`, newData)) as { _id: string }

            graphic = {
                ...data,
                name: newName,
                _id,
            }

            await dispatch(getGraphicFulfilled(graphic))
            await dispatch(ActionCreators.clearHistory())

            if (graphic._id) dispatch(getArchivedGraphicsAsync(graphic._id, ARCHIVED_QUERY_OPTIONS))

            if (successMessage) {
                const { success } = useToast()
                success(successMessage)
            }
        } catch (error: any) {
            await dispatch(getGraphicFillActualData(data))
            await dispatch(getGraphicRejected(error.message))
        }
    }

export const publishOrUnPublishGraphicAsync =
    (id: string, data: { isPublished: boolean; price: number | null }): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            const graphic = await put<GraphicT>(`graphics/${id}/publish`, data)
            dispatch(getGraphicFulfilled(graphic))

            const { success } = useToast()

            let successMessage: string
            if (data.isPublished) {
                successMessage = 'graphics:successMessage.graphicDetailPublish'
            } else {
                successMessage = 'graphics:successMessage.graphicRemoveFromStore'
            }
            success(successMessage)
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export const setFinalGraphicAsync =
    (id: string, data: { isFinal: boolean }): AppThunkT =>
    async (dispatch) => {
        try {
            await put<GraphicT>(`graphics/${id}/final`, data)
            const { success } = useToast()
            success('graphics:successMessage.graphicDetailPublish')
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export const exportGraphicAsync =
    (id: string, infoMessage?: string, onExport?: () => void): AppThunkT =>
    async () => {
        try {
            await put(`graphics/${id}/export`, undefined)
            onExport?.()
            const { info } = useToast()
            if (infoMessage) info(infoMessage)
        } catch (error: any) {
            console.error(error)
        }
    }

export const purchaseGraphicAsync =
    (graphicId: string, companyId: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            dispatch(getCompaniesPending())
            const graphic = await post<{ _id: string } & GraphicT>(
                `companies/${companyId}/purchase`,
                { graphicId }
            )

            //refresh account balance coins value for companies
            const companies = await get<ListT<CompanyT>>('companies/current', undefined)
            dispatch(getCompaniesFulfilled(companies))

            dispatch(getGraphicFulfilled(graphic))
            const { success } = useToast()
            success('graphics:successMessage.graphicPurchase')
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export const deleteGraphicAsync =
    (id: string): AppThunkT =>
    async (dispatch) => {
        try {
            dispatch(getGraphicPending())
            const graphic = await del<GraphicT>(`graphics/${id}`, undefined)
            await del('files', undefined, { key: `${S3_PREFIX}/${id}` })
            dispatch(getGraphicFulfilled(graphic))
            const { success } = useToast()
            success('graphics:successMessage.delete')
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export const deleteAssetAsync =
    (id: string, assetType: string): AppThunkT =>
    async (dispatch) => {
        try {
            const msgAssetType: string = getAssetTypeMsg(assetType)
            await del(`assets/${id}`, undefined)
            const { success } = useToast()
            success(`graphics:successMessage.delete${msgAssetType}`)
        } catch (error: any) {
            dispatch(getGraphicRejected(error.message))
        }
    }

export default graphicSlice.reducer
