import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import { Box, Button, Dialog, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { dateToString } from '../../helpers/date.helpers'
import { getFullNameString } from '../../helpers/string.helpers'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { useReduxForm } from '../../hooks/useReduxForm'
import {
    GraphicPriceT,
    graphicPriceSchema,
    publishOrUnPublishGraphicAsync,
} from '../../store/slices/graphic.slice'
import PreviewScreenWrapper from '../GraphicCommon/PreviewScreenWrapper'
import { Transition } from '../common/Transition'
import TextField from '../common/form/TextField'
import { getGraphicAfterPublishUnPublish } from '../../store/slices/graphics.slice'

const DEFAULT_VALUES = { price: 0 }
interface Props {
    open: boolean
    fileContent: Blob | undefined
    onClose: (refresh: boolean) => void
    setFirstPage: () => void
}

function GraphicPublicDetail({ open, fileContent, onClose, setFirstPage }: Props) {
    const theme = useTheme()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic)

    const onPublishGraphic = async (data: GraphicPriceT) => {
        const newData = { isPublished: true, price: data.price }
        if (graphic.data._id) {
            dispatch(publishOrUnPublishGraphicAsync(graphic.data._id, newData))
            dispatch(
                getGraphicAfterPublishUnPublish({
                    id: graphic.data._id,
                    isPublished: newData.isPublished,
                })
            )
        }
        onClose(true)
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: graphicPriceSchema,
        defaultValues: DEFAULT_VALUES,
    })

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'xl'}
            sx={{ zIndex: 1500 }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onPublishGraphic)}
                noValidate
                sx={{
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Grid container p={4} spacing={4} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <PreviewScreenWrapper fileContent={fileContent} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid
                            container
                            py={2}
                            direction="column"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3}
                        >
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    sx={{ wordBreak: 'break-word' }}
                                >
                                    {graphic.data.name}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {graphic.data.description}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" gutterBottom>
                                    <PublicRoundedIcon sx={{ mr: 1 }} />
                                    {t('graphics:graphicPublishDetailTitle')}
                                </Typography>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs={3}>
                                        <TextField
                                            control={control}
                                            name="price"
                                            type="number"
                                            error={errors.price}
                                            fullWidth
                                            label={t('graphics:price')}
                                            inputProps={{ inputProps: { min: 0, max: 5 } }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            disabled={isSubmitting}
                                            onClick={() => onClose(false)}
                                        >
                                            {t('disagree')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disabled={graphic.data.isPublished}
                                        >
                                            {t('graphics:publishDetail')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    {t('graphics:created')}
                                    {graphic.data.created &&
                                        dateToString(graphic.data.created)}{' '}
                                    {t('graphics:by')}
                                    {
                                        <i>
                                            {!graphic.data.user?.firstName &&
                                            !graphic.data.user?.lastName
                                                ? t('graphics:anonymous')
                                                : ''}
                                        </i>
                                    }
                                    {getFullNameString(
                                        graphic.data.user?.firstName,
                                        graphic.data.user?.lastName
                                    )}
                                </Typography>
                                <Typography variant="body2">
                                    {t('graphics:lastUpdated')}
                                    {graphic.data.updated &&
                                        dateToString(graphic.data.updated)}{' '}
                                    {t('graphics:by')}
                                    {
                                        <i>
                                            {!graphic.data.updatedBy?.firstName &&
                                            !graphic.data.updatedBy?.lastName
                                                ? t('graphics:anonymous')
                                                : ''}
                                        </i>
                                    }
                                    {getFullNameString(
                                        graphic.data.updatedBy?.firstName,
                                        graphic.data.updatedBy?.lastName
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}
export default GraphicPublicDetail
