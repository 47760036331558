import { FileResponse } from './ajax.helpers'
import { getFileId, getImagePath } from './file.helpers'

export type ObjectStyles = {
    left: number
    top: number
    width: number
    height: number
}

export const getWidth = (object: AnySceneObjectT) => {
    return object.styles.find((style) => style.property === 'width')
}

export const getHeight = (object: AnySceneObjectT) => {
    return object.styles.find((style) => style.property === 'height')
}

export const getResultMultiAlignment = (
    object: AnySceneObjectT,
    value: string,
    styles: ObjectStyles
) => {
    switch (value) {
        case 'horizontalLeft':
            return styles.left
        case 'horizontalRight':
            return styles.left + styles.width - Number(getWidth(object)?.value)
        case 'horizontalCenter':
            return styles.width / 2 + styles.left - Number(getWidth(object)?.value) / 2
        case 'verticalTop':
            return styles.top
        case 'verticalCenter':
            return styles.height / 2 + styles.top - Number(getHeight(object)?.value) / 2
        case 'verticalBottom':
            return styles.top + styles.height - Number(getHeight(object)?.value)
        default:
            return 0
    }
}

/**
 * Returns object styles (left, top, with, height) from object
 * @param object AnySceneObjectT
 */
export const getObjectPositioning = (object: AnySceneObjectT) => {
    return {
        position: object.styles.find((style) => style.property === 'position')?.value,
        left: object.styles.find((style) => style.property === 'left')?.value,
        top: object.styles.find((style) => style.property === 'top')?.value,
        width: object.styles.find((style) => style.property === 'width')?.value,
        height: object.styles.find((style) => style.property === 'height')?.value,
    }
}

/**
 * Returns gab between object in selection,
 * @param wrapperObjectValue number,  height or width of wrapper object
 * @param values number[], values is array of heights or widths
 * @param gapNumbers number, numbers of gap between objects
 */
export const getDistributionGap = (
    wrapperObjectValue: number,
    values: number[],
    gapNumbers: number
): number => {
    const totalValues = values.reduce((a, b) => a + b, 0) // Summing the array
    const difference = Number(wrapperObjectValue) - Number(totalValues) // Subtracting the sum from the wrapperObjectValue
    const gap = difference / gapNumbers // Dividing the difference by gapNumbers
    return Math.round(gap)
}

/**
 * Returns desc array of objects sorted by object style
 * @param array AnySceneObjectT[]
 * @param sortedBy 'left' | 'top' | 'width' | 'height'
 */
export const getSortedObjectArray = (
    array: AnySceneObjectT[],
    sortedBy: 'left' | 'top' | 'width' | 'height'
) => {
    return array.sort((a, b) =>
        getObjectPositioning(a)[sortedBy] > getObjectPositioning(b)[sortedBy]
            ? 1
            : getObjectPositioning(b)[sortedBy] > getObjectPositioning(a)[sortedBy]
            ? -1
            : 0
    )
}

export const EMPTY_IMAGE_SRC =
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export const getImageSrc = (object: ImageI | SequenceI, assets: FileResponse[]) => {
    const backgroundImage = object.fill.find((f) => f.property === 'backgroundImage')
    if (backgroundImage) {
        const match = /\(["']?([^"')]+)["']?\)/.exec(backgroundImage.value)
        if (match) {
            let url = match[1]
            if (assets.length) {
                const filename = url.split('/').pop() as string
                const file = assets.find((f) => f._id === getFileId(filename))
                if (file) {
                    url = getImagePath(file)
                }
            }
            return url
        }
    }

    return EMPTY_IMAGE_SRC
}

export const preloadTween = (from: SimpleStyleT[]) => {
    from.filter((f) => f.property === 'attr.src').forEach((f) => {
        const image = new Image()
        image.src = f.value
    })
}

export const processTween = (from: SimpleStyleT[]) => {
    return from.map((f) => {
        if (f.property === 'attr.src') {
            const parts = f.value.split('/')
            parts[parts.length - 1] = `t-${parts[parts.length - 1]}`
            return { ...f, value: parts.join('/') }
        }
        return f
    })
}
