import JSONfn from 'json-fn'
import { useCallback, useEffect, useState } from 'react'
import { showError } from '../store/slices/error.slice'
import { useAppDispatch } from './useRedux'

function useHtmlForPreview(fileContent: Blob | undefined) {
    const [objectsMeta, setObjectsMeta] = useState<AnySceneObjectT[]>([])
    const [animationsMeta, setAnimationsMeta] = useState<AnimationI[]>([])
    const [JSONParamsMeta, setJSONParamsMeta] = useState<JSONParamI[]>([])
    const dispatch = useAppDispatch()

    const [htmlSrc, setHtmlSrc] = useState<string>('')

    const checkAndTrimDollars = (str: string) => {
        str = str.replace(/[\b\r\n]/g, '')
        if (str.substring(0, 2) === '$$' && str.substring(str.length - 2, str.length) === '$$') {
            return str.substring(2, str.length - 2)
        } else return str
    }

    const processPreview = useCallback(
        (htmlString: string): string => {
            var tempHtml = document.createElement('html')
            tempHtml.innerHTML = htmlString

            // find all objects
            let objectState = Object?.values(tempHtml.getElementsByTagName('meta')).filter(
                (metaItem: HTMLMetaElement) => metaItem.getAttribute('property') === 'objects'
            )
            let objects: AnySceneObjectT[]
            if (objectState.length !== 0) {
                try {
                    objects = JSONfn.parse(
                        objectState.map((metaItem: HTMLMetaElement) => metaItem.content)[0]
                    )
                    setObjectsMeta(objects)
                } catch (error: any) {
                    dispatch(showError('animationsCorrupted'))
                    objects = []
                    setObjectsMeta(objects)
                }
            }

            // find all animations
            let animationState = Object?.values(tempHtml.getElementsByTagName('meta')).filter(
                (metaItem: HTMLMetaElement) => metaItem.getAttribute('property') === 'animations'
            )
            let animations: AnimationI[] = []
            if (animationState.length !== 0) {
                try {
                    animations = JSONfn.parse(
                        animationState.map((metaItem: HTMLMetaElement) => metaItem.content)[0]
                    )
                    objects = JSONfn.parse(
                        objectState.map((metaItem: HTMLMetaElement) => metaItem.content)[0]
                    )
                    objects.forEach((obj) => {
                        if (obj.animations) {
                            Object.values(obj.animations).forEach((animation) => {
                                if (animation) animations.push(animation)
                            })
                        }
                    })
                    setAnimationsMeta(animations)
                } catch (error: any) {
                    dispatch(showError('animationsCorrupted'))
                    animations = []
                    setAnimationsMeta(animations)
                }
            }

            let jsonParamsState = Object?.values(tempHtml.getElementsByTagName('meta')).filter(
                (metaItem: HTMLMetaElement) => metaItem.getAttribute('property') === 'JSONparams'
            )
            let jsonParams: JSONParamI[] = []
            if (jsonParamsState.length !== 0) {
                try {
                    jsonParams = JSONfn.parse(
                        jsonParamsState.map((metaItem: HTMLMetaElement) =>
                            checkAndTrimDollars(metaItem.content)
                        )[0]
                    )
                    setJSONParamsMeta(jsonParams)
                } catch (error: any) {
                    dispatch(showError('parametersCorrupted'))
                }
            }

            // find all styles
            const styles: string = Object.values(tempHtml.getElementsByTagName('style'))
                .map((styleItem: HTMLStyleElement) => styleItem.outerHTML)
                .join()

            // find scene wrapped into #canvas
            const canvas: string = Object.values(tempHtml.getElementsByTagName('div'))
                .filter((divItem: HTMLDivElement) => divItem.id === 'canvas')
                .map((divItem: HTMLDivElement) => divItem.outerHTML)[0]

            return styles + canvas
        },
        [dispatch]
    )

    useEffect(() => {
        const reader = new FileReader()

        if (fileContent) reader.readAsText(fileContent)
        reader.onload = function () {
            reader.result && setHtmlSrc(processPreview(reader.result.toString()))
        }
    }, [fileContent, processPreview])

    return { objectsMeta, animationsMeta, htmlSrc, JSONParamsMeta }
}
export default useHtmlForPreview
