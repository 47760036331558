import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { animationNode } from './animationNode'
import { UpdateOffsetAnimationT } from './animationTypes'

export const durationAnimationBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationT>
) => {
    const offset = action.payload.offset
    const objectId = action.payload.objectId
    const animation = action.payload.animation
    state.value = {
        ...state.value,
        [objectId]: {
            ...state.value[objectId],
            animations: {
                ...state.value[objectId].animations,
                [animation.id]: animationNode(
                    state.value[objectId].animations[animation.id] ?? ({} as AnimationI),
                    {
                        property: 'duration',
                        value: offset,
                    }
                ),
            },
        },
    }
}
