import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { UpdateOffsetAnimationsT } from './animationTypes'
import { compareAnimations } from './compareAnimations'
import { startOffsetAnimationBaseAction } from './startOffsetAnimationBaseAction'

export const startOffsetAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationsT>
) => {
    const offset = action.payload.offset
    const selectedAnimations = action.payload.selectedAnimations
    selectedAnimations
        .slice()
        .sort(compareAnimations)
        .forEach((animation: AnimationI) => {
            startOffsetAnimationBaseAction(state, {
                payload: { animation, offset, objectId: animation.objectId },
                type: action.type,
            })
        })
}
