import { PayloadAction } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { AnimationUpdT, ObjectsStateT } from '../../objects.slice'

const animationNode = (animation: AnimationI, payload: AnimationUpdT): AnimationI => {
    return set(animation, payload.property, payload.value)
}

export const updateAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AnimationUpdT>
) => {
    const id = action.payload.id
    const objectId = action.payload.objectId
    state.value = {
        ...state.value,
        [objectId]: {
            ...state.value[objectId],
            animations: {
                ...state.value[objectId].animations,
                [id]: animationNode(
                    state.value[objectId].animations[id] ?? ({} as AnimationI),
                    action.payload
                ),
            },
        },
    }
}
