import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { AddAnimationT } from './animationTypes'

export const addAnimationBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AddAnimationT>
) => {
    const animationId: string = action.payload.animation.id
    const objectId: string = action.payload.objectId
    state.value = {
        ...state.value,
        [objectId]: {
            ...state.value[objectId],
            animations: {
                ...state.value[objectId].animations,
                [animationId]: action.payload.animation,
            },
        },
    }
}
