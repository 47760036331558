export const selectAllAnimations = (objects: AnySceneObjectT[]): AnimationI[] => {
    let animations: AnimationI[] = []
    objects.forEach((object) => {
        if (object && object.animations) {
            Object.values(object.animations).forEach((animation) => {
                if (animation) animations.push(animation)
            })
        }
    })
    return animations
}
