export const updateObjectStyles = (object: AnySceneObjectT, dimensions: Record<string, any>) => {
    let newStyles: SimpleStyleT[] = object.styles.slice()

    Object.entries(dimensions).forEach(([property, value]) => {
        const style: SimpleStyleT | undefined = newStyles.find(
            (style) => style.property === property
        )
        if (style) {
            newStyles = newStyles.map((style) => {
                if (style.property === property) {
                    return {
                        ...style,
                        value: value,
                    }
                } else {
                    return style
                }
            })
        }
    })
    return newStyles
}
