import { PayloadAction } from '@reduxjs/toolkit'
import { AnimationsT, ObjectsStateT } from '../../objects.slice'

export const duplicateAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<AnimationsT>
) => {
    const animations = action.payload.animations
    animations.forEach((animation) => {
        const objectId = animation.objectId
        state.value = {
            ...state.value,
            [objectId]: {
                ...state.value[objectId],
                animations: {
                    ...state.value[objectId].animations,
                    [animation.id]: animation,
                },
            },
        }
    })
}
