import ImageIcon from '@mui/icons-material/Image'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import * as yup from 'yup'
import { checkImage } from '../../../helpers/images.helpers'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppSelector } from '../../../hooks/useRedux'
import { useReduxForm } from '../../../hooks/useReduxForm'
import { Transition } from '../../common/Transition'
import TextField from '../../common/form/TextField'
import SelectAttributes from '../DataSource/SelectAttributes'

interface Props {
    open: boolean
    onClose: () => void
    parentId: string | null
    object?: AnySceneObjectT
}
const DEFAULT_VALUES = {
    imgSrc: '',
}
type ImageSrcDataT = {
    imgSrc: string
}

const IS_VALUE_ATTRIBUTE = /\{.*\}$/g
const IS_VALUE_URL =
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g

const ImageSrcDialog = ({ open, onClose, parentId, object }: Props) => {
    const {
        readImageFileFromURL,
        replaceImageSourceFromUrl,
        readImageFileFromAsset,
        replaceImageSourceFromAsset,
    } = useObjectActions()
    const assets = useAppSelector((state) => state.assets.data)
    const defaultData = useMemo(() => {
        return {
            ...DEFAULT_VALUES,
            imgSrc: object?.fill
                .find((f) => f.property === 'backgroundImage')
                ?.value.replace(/url\("|"\)/g, ''),
        }
    }, [object])

    const onSubmitImageSrc = async (data: ImageSrcDataT) => {
        if (data.imgSrc.match(IS_VALUE_ATTRIBUTE)) {
            if (object) {
                replaceImageSourceFromAsset(data.imgSrc, object)
            } else {
                readImageFileFromAsset(data.imgSrc, parentId)
            }
        } else {
            if (object) {
                replaceImageSourceFromUrl(data.imgSrc, object)
            } else {
                readImageFileFromURL(data.imgSrc, parentId)
            }
        }
        handleClose()
    }

    const checkAttributeExist = (imgSrc: string) => {
        const [slug, name] = imgSrc.replace(/{|}/g, '').split('/')
        const asset = assets.find((asset) => asset.slug === slug)
        if (asset) {
            return asset.attributes.find((attribute) => attribute.name === name) ? true : false
        } else return false
    }

    const checkImgSrcValue = async (imgSrc: string | undefined) => {
        if (imgSrc) {
            if (imgSrc?.match(IS_VALUE_URL)) {
                return await checkImage(imgSrc)
            } else if (imgSrc?.match(IS_VALUE_ATTRIBUTE)) {
                return checkAttributeExist(imgSrc)
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const imageSrcSchema = yup.object({
        imgSrc: yup.string().test(
            'is-url-or-attribute',
            () => t('editor:imageSrcDialog.imgSrcError'),
            async (value) => checkImgSrcValue(value)
        ),
    })

    const {
        control,
        reset,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
    } = useReduxForm({
        schema: imageSrcSchema,
        defaultValues: defaultData,
    })

    const imgSrc = useController({ name: 'imgSrc', control })

    const handleSetImageFromAttribute = (imageUrlFromAssets: string) => {
        imgSrc.field.onChange(imageUrlFromAssets)
    }

    const handleClose = () => {
        reset(DEFAULT_VALUES)
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            keepMounted
            sx={{ zIndex: 1400 }}
            maxWidth={'xl'}
        >
            <Box component="form" onSubmit={handleSubmit(onSubmitImageSrc)} m={3} width={800}>
                <DialogTitle component={'h4'} sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                    <ImageIcon sx={{ mr: 1 }} fontSize="large" />
                    {object
                        ? t('editor:imageSrcDialog.replaceTitle')
                        : t('editor:imageSrcDialog.newTitle')}
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ height: 20, ml: 0.5, mb: 1 }}>
                        <Typography>{t('editor:imageSrcDialog.URLImageTitle')}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                        <TextField
                            control={control}
                            fullWidth
                            name="imgSrc"
                            error={errors.imgSrc}
                            label={t('image src')}
                            disabled={false}
                        />
                        <Box mt={0.4}>
                            <SelectAttributes handleChange={handleSetImageFromAttribute} />
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={handleClose}
                        disabled={isSubmitting}
                    >
                        {t('disagree')}
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting || !isValid}>
                        {object
                            ? t('editor:imageSrcDialog.buttonReplace')
                            : t('editor:imageSrcDialog.buttonCreate')}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ImageSrcDialog
