import ReactSplit, { SplitDirection } from '@devbookhq/splitter'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { setOpenSaveForm, setOpenVersionHistoryDetail } from '../../store/slices/editor.slice'
import { ScrollableContainer } from '../../style/styles'
import GraphicSaveForm from '../Graphic/GraphicSaveForm'
import EmptyPanel from '../common/EmptyPanel'
import AnimationTools from './AnimationsNavbar/AnimationTools'
import ObjectTools from './RightSideNavbar/ObjectTools'
import VersionHistoryForm from './VersionHistoryForm'

const RightSideNavbar = () => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        return () => {
            dispatch(setOpenSaveForm(false))
            dispatch(setOpenVersionHistoryDetail(false))
        }
    }, [dispatch])

    const { visibleSaveForm, versionHistoryDetail } = useAppSelector((state) => state.editor.value)

    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)
    const selectedAnimationsIds: string[] = useAppSelector(
        (state) => state.activeAnimation.selected
    )
    if (visibleSaveForm) {
        return (
            <ScrollableContainer>
                <GraphicSaveForm />
            </ScrollableContainer>
        )
    } else if (versionHistoryDetail) {
        return (
            <ScrollableContainer>
                <VersionHistoryForm />
            </ScrollableContainer>
        )
    } else {
        if (selectedAnimationsIds.length === 0 && selectedObjectIds.length === 0)
            return <EmptyPanel text1="Object settings" text2="Select an object" />
        else if (selectedObjectIds.length >= 1 && selectedAnimationsIds.length < 1)
            return <ObjectTools objectId={selectedObjectIds[0]} />
        else if (selectedObjectIds.length >= 1 && selectedAnimationsIds.length >= 1)
            return (
                <ReactSplit
                    direction={SplitDirection.Vertical}
                    initialSizes={[60, 40]}
                    gutterClassName="custom-gutter-vertical"
                >
                    <ObjectTools objectId={selectedObjectIds[selectedObjectIds.length - 1] ?? ''} />
                    <AnimationTools animationId={selectedAnimationsIds[0]} />
                </ReactSplit>
            )
        else return <></>
    }
}

export default RightSideNavbar
