import { Container, Grid } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import GraphicTile from '../components/Graphic/GraphicTile'
import GraphicDetail from '../components/Graphic/GraphicsDetail'
import BottomPage from '../components/GraphicCommon/BottomPage'
import EmptyPage from '../components/GraphicCommon/EmptyPage'
import EmptyPageLoading from '../components/GraphicCommon/EmptyPageLoading'
import GalleryPageHeader from '../components/GraphicCommon/GalleryPageHeader'
import { FilterT, SortT } from '../components/common/DataGrid'
import LoadingBackdrop from '../components/common/LoadingBackdrop'
import { FILTER_ATTRIBUTE, FILTER_CREATED_BY, FILTER_TYPE } from '../constants/filterLists'
import { ORDER_GRAPHIC } from '../constants/sortLists'
import useId from '../hooks/useId'
import useInfinityScroll from '../hooks/useInfinityScroll'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { getCompaniesCurrentAsync } from '../store/slices/companies.slice'
import { getCompanyStatsAsync } from '../store/slices/companyStats.slice'
import {
    deleteAssetAsync,
    deleteGraphicAsync,
    getAssetAsync,
    getGraphicAndFileAsync,
} from '../store/slices/graphic.slice'
import {
    getGraphicsAfterDelete,
    getGraphicsClearState,
    getGraphicsCurrentAsync,
    GraphicAsset,
} from '../store/slices/graphics.slice'

const DEFAULT_TYPE = 'graphic'

function Graphics() {
    const navigate = useNavigate()
    const { galleryFilters } = useAppSelector((state) => state.editor.value)

    const filterCompanyId = galleryFilters.companyId ?? ''
    const filterAttribute = galleryFilters.attribute ?? ''
    const filterCreatedBy = galleryFilters.createdBy ?? ''
    const filterType = galleryFilters.type
    const sortedBy = galleryFilters.sortedBy ?? ''

    const [searchTxt, setSearchTxt] = useState<string>('')

    const [openDetail, setOpenDetail] = useState<boolean>(false)
    const fileContent = useAppSelector((state) => state.file.data)
    const fileStatus = useAppSelector((state) => state.file.status)

    const dispatch = useAppDispatch()

    const onError = useCallback(() => {
        setOpenDetail(false)
        navigate('/app/graphics')
    }, [navigate])

    const callback = useCallback(
        async (id: string) => {
            setOpenDetail(true)
            const parts = id.split('-')
            if (parts[0] === DEFAULT_TYPE) {
                await dispatch(getGraphicAndFileAsync(parts[1], onError))
            } else {
                await dispatch(getAssetAsync(parts[1], onError))
            }
        },
        [dispatch, onError]
    )
    const { id, setId } = useId(callback)

    const handleOpenDetail = (graphic: GraphicAsset) => {
        const id = `${graphic.type ?? DEFAULT_TYPE}-${graphic._id}`
        setId(id)
    }
    const handleClearSetIdAndClose = () => {
        setId(null)
    }

    //close graphicDetail when use backButton in browser or close dialog
    useEffect(() => {
        if (!id) {
            setOpenDetail(false)
        }
    }, [id])

    const graphics = useAppSelector((state) => state.graphics)
    const user = useAppSelector((state) => state.user)

    const { scrollState, setFirstPage, setNextPage } = useInfinityScroll(
        graphics.data.length,
        graphics.total
    )

    const handleDelete = async (graphic: GraphicAsset) => {
        if (graphic.type) {
            await dispatch(deleteAssetAsync(graphic._id, graphic.type))
        } else {
            await dispatch(deleteGraphicAsync(graphic._id))
        }
        await dispatch(getGraphicsAfterDelete({ id: graphic._id }))
        await dispatch(getCompanyStatsAsync(graphic.companyId, true))
    }

    const debouncedSearchTxt = useDebounce<string>(searchTxt)

    const handleLoadMoreContent = () => {
        setNextPage()
    }

    const filter = useMemo(() => {
        const searchFilter = debouncedSearchTxt
            ? {
                  $or: [
                      { tags: { $eq: debouncedSearchTxt } },
                      { description: { $regex: debouncedSearchTxt, $options: 'i' } },
                      { name: { $regex: debouncedSearchTxt, $options: 'i' } },
                  ],
              }
            : {}
        const filterOption: FilterT = {
            $and: [
                filterCompanyId ? { companyId: filterCompanyId } : {},
                FILTER_ATTRIBUTE.find((x) => x.name === filterAttribute)?.getResultFilter() || {},
                FILTER_TYPE.find((x) => x.name === filterType)?.getResultFilter() || {},
                FILTER_CREATED_BY.find((x) => x.name === filterCreatedBy)?.getResultFilter(
                    user.value._id
                ) || {},
                searchFilter,
            ],
        }
        return filterOption
    }, [
        debouncedSearchTxt,
        filterAttribute,
        filterCompanyId,
        filterCreatedBy,
        user.value._id,
        filterType,
    ])

    const sort = useMemo(() => {
        const sortOption: SortT = ORDER_GRAPHIC.find(
            (x) => x.name === sortedBy
        )?.getResultSort() || {
            updated: -1,
        }
        return sortOption
    }, [sortedBy])

    useEffect(() => {
        setFirstPage()
        dispatch(getGraphicsClearState())
    }, [dispatch, filter, setFirstPage, sort])

    useEffect(() => {
        dispatch(
            getGraphicsCurrentAsync({
                filter,
                sort,
                skip: scrollState.skip,
                limit: scrollState.limit,
            })
        )
        // dependent on scrollState not on filter and sort
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, scrollState])

    const handleNavigateToEditor = () => {
        navigate('/app/editor')
    }

    useEffect(() => {
        dispatch(getCompaniesCurrentAsync())
    }, [dispatch])

    return (
        <Container component="main" maxWidth="xl">
            <GalleryPageHeader
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
                sortedBy={sortedBy}
                title={'graphics:myGraphicsTitle'}
                createNew={handleNavigateToEditor}
                filterCreatedBy={filterCreatedBy}
                filterAttribute={filterAttribute}
                filterCompanyId={filterCompanyId}
                filterType={filterType}
            />
            <Grid className="my-graphics-content" container spacing={3} p={2}>
                {graphics.status && graphics.data.length === 0 && (
                    <EmptyPage
                        header={'emptyGalleryHeader'}
                        info={'emptyGalleryInfo'}
                        link={'goToEditorLink'}
                    />
                )}
                {graphics.data.map((g, index) => {
                    return (
                        <GraphicTile
                            key={index.toString()}
                            graph={g}
                            onDelGraphic={handleDelete}
                            handleOpenDetail={handleOpenDetail}
                            setFirstPage={() => setFirstPage()}
                        />
                    )
                })}
                {openDetail && (
                    <>
                        <GraphicDetail
                            open={openDetail}
                            onClose={() => handleClearSetIdAndClose()}
                            fileContent={fileContent?.blob}
                            setFirstPage={() => setFirstPage()}
                        />
                        <LoadingBackdrop open={!fileStatus} />
                    </>
                )}
                {graphics.data.length < graphics.total && (
                    <BottomPage
                        loading={!graphics.status}
                        loaded={graphics.data.length}
                        total={graphics.total}
                        handleLoadMoreContent={handleLoadMoreContent}
                    />
                )}
                {!graphics.status && graphics.data.length === 0 && <EmptyPageLoading />}
            </Grid>
        </Container>
    )
}

export default Graphics
