import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    useTheme,
} from '@mui/material'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import * as yup from 'yup'
import useId from '../../../hooks/useId'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { useReduxForm } from '../../../hooks/useReduxForm'
import useTimeStepsDensity from '../../../hooks/useTimeStepsDensity'
import { getCompanyStatsAsync } from '../../../store/slices/companyStats.slice'
import { setPreferencesAsync } from '../../../store/slices/editor.slice'
import { setTimelinePreferencesAsync } from '../../../store/slices/timelinePreferences.slice'
import { Transition } from '../../common/Transition'
import RadioGroupButton from '../../common/form/RadioGroupButton'
import Select from '../../common/form/Select'
import TextField from '../../common/form/TextField'

interface Props {
    open: boolean
    hideCloseButton?: boolean
    disabled?: boolean
    onClose: () => void
}
type SettingsData = {
    width: number
    height: number
    framerate: number
    resolution: string
    companyId: string
}

const settingsSchema = yup.object({
    width: yup.number().transform((curr, orig) => (orig === '' ? 0 : curr)),
    height: yup.number().transform((curr, orig) => (orig === '' ? 0 : curr)),
    companyId: yup.string().required('company:companyRequired'),
})

const CanvasSettingDialog = ({ open, disabled, hideCloseButton, onClose }: Props) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const { canvasResolution, companyId } = useAppSelector((state) => state.editor.value.settings)
    const framerate = useAppSelector((state) => state.timelinePreferences.value.framerate)
    const timelineZoom = useAppSelector((state) => state.timelinePreferences.value.timelineZoom)
    const currentCompanies = useAppSelector((state) => state.companies)
    const { id } = useId()

    const privateCompanyId = currentCompanies.data.find((company) => company.isPrivate)?._id
    const DEFAULT_VALUES: SettingsData = useMemo(() => {
        return {
            width: canvasResolution[0],
            height: canvasResolution[1],
            framerate: framerate,
            companyId: companyId ?? privateCompanyId ?? '',
            resolution:
                canvasResolution[0] === 1920 && canvasResolution[1] === 1080
                    ? 'fullHD'
                    : canvasResolution[0] === 3840 && canvasResolution[1] === 2160
                    ? '4K'
                    : '',
        }
    }, [canvasResolution, companyId, framerate, privateCompanyId])
    const { setDensityOfTimeline } = useTimeStepsDensity()

    const onSubmitSettings = (data: SettingsData) => {
        dispatch(setPreferencesAsync('canvasResolution', [data.width, data.height]))
        dispatch(setTimelinePreferencesAsync('framerate', Number(data.framerate)))
        dispatch(setPreferencesAsync('companyId', data.companyId))
        dispatch(getCompanyStatsAsync(data.companyId, true))
        setDensityOfTimeline(timelineZoom)
        onClose()
    }

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useReduxForm({
        schema: settingsSchema,
        defaultValues: DEFAULT_VALUES,
    })

    const resolution = useWatch({
        control,
        name: 'resolution',
    })
    const disabledWidthHeight = !!String(resolution)

    useEffect(() => {
        if (String(resolution) === 'fullHD') {
            setValue('width', 1920)
            setValue('height', 1080)
        } else if (String(resolution) === '4K') {
            setValue('width', 3840)
            setValue('height', 2160)
        } else {
            setValue('width', 0)
            setValue('height', 0)
        }
    }, [resolution, setValue])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmitSettings)}
                noValidate
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    m: 3,
                    maxWidth: { lg: 500, sm: 'auto' },
                }}
            >
                <DialogTitle component="h6">{t('editor:settings.title')}</DialogTitle>
                <DialogContent>
                    <Grid container mb={1}>
                        <Select
                            control={control}
                            required
                            name="companyId"
                            label={t('company:title')}
                            fullWidth
                            labelField="name"
                            valueField="_id"
                            error={errors.companyId}
                            options={currentCompanies.data}
                        />
                    </Grid>

                    <Grid container>
                        <Grid item md={6} xs={6} pr={2}>
                            <Grid container>
                                <Grid item>
                                    <RadioGroupButton
                                        control={control}
                                        name="resolution"
                                        title={t('editor:settings.resolution')}
                                        required={true}
                                        options={[
                                            {
                                                value: 'fullHD',
                                                title: t('editor:settings.fullHD'),
                                            },
                                            {
                                                value: '4K',
                                                title: t('editor:settings.4K'),
                                            },
                                            {
                                                value: '',
                                                title: t('editor:settings.custom'),
                                            },
                                        ]}
                                        error={errors.license}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        control={control}
                                        name="width"
                                        type="number"
                                        disabled={disabledWidthHeight}
                                        error={errors.width}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        control={control}
                                        name="height"
                                        type="number"
                                        disabled={disabledWidthHeight}
                                        error={errors.height}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <RadioGroupButton
                                control={control}
                                disabled={disabled}
                                name="framerate"
                                title={t('editor:settings.framerateTitle')}
                                required={true}
                                options={[
                                    {
                                        value: 1,
                                        title: t('editor:settings.seconds'),
                                    },
                                    {
                                        value: 25,
                                        title: t('editor:settings.25fps'),
                                    },
                                    {
                                        value: 50,
                                        title: t('editor:settings.50fps'),
                                    },
                                ]}
                                error={errors.license}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!hideCloseButton && (
                        <Button onClick={onClose} sx={{ px: 2 }} variant="text" color="inherit">
                            {t('disagree')}
                        </Button>
                    )}
                    <Button type="submit" disabled={isSubmitting} sx={{ px: 2 }}>
                        {id ? t('submitShort') : t('submitCreate')}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default CanvasSettingDialog
