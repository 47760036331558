import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded'
import PlaylistPlayRoundedIcon from '@mui/icons-material/PlaylistPlayRounded'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAnimationActions } from '../../../hooks/useAnimationActions'

interface Props {
    object: AnySceneObjectT
    invisible: boolean
}

const ObjectAnimationSelection = ({ object, invisible }: Props) => {
    const { t } = useTranslation()
    const { addNewAnimation } = useAnimationActions()
    const handleAddAnimation = () => addNewAnimation(object.id, 0)

    const hasAnimations: boolean =
        (object.animations ? Object.keys(object.animations).length : 0) > 0
    const tooltipTitle: string = !hasAnimations
        ? t('editor:animate')
        : Object.keys(object.animations).length === 1
        ? `${Object.keys(object.animations).length} ${t('editor:singleAnimationInTimeline')}`
        : `${Object.keys(object.animations).length} ${t('editor:moreAnimationsInTimeline')}`

    return (
        <Tooltip title={tooltipTitle}>
            <span>
                {' '}
                {/* Span makes tooltip to work with disabled button – https://mui.com/material-ui/react-tooltip/#disabled-elements */}
                <IconButton onClick={handleAddAnimation} disabled={hasAnimations}>
                    {hasAnimations ? (
                        <PlaylistPlayRoundedIcon fontSize="small" />
                    ) : (
                        <PlaylistAddRoundedIcon fontSize="small" />
                    )}
                </IconButton>
            </span>
        </Tooltip>
    )
}
export default ObjectAnimationSelection
