import { createAnimation } from '../helpers/creators.helpers'
import {
    addSelectedAnimationsAction,
    clearSelectedAnimationsAction,
    removeSelectedAnimationsAction,
} from '../store/slices/activeAnimation.slice'
import { addObjectToSelectedObjectsAction } from '../store/slices/activeObject.slice'
import { refreshAllStyles, updateMasterTimeline } from '../store/slices/masterTimeline.slice'
import {
    addAnimationAction,
    createSequenceAnimationsAction,
    deleteAnimationsAction,
    duplicateAnimationsAction,
    updateObjectAnimationAction,
} from '../store/slices/objects.slice'
import { useAppDispatch, useAppSelector } from './useRedux'
import { useTimelineActions } from './useTimelineActions'

export const useAnimationActions = () => {
    const dispatch = useAppDispatch()
    const timeToSeek = useAppSelector((state) => state.masterTimeline.time)
    const { refreshMasterTimeline } = useTimelineActions()
    /* ACTIVE ANIMATION HANDLERS */

    const setActiveAnimations = (ids: string[], objectId: string) => {
        dispatch(addSelectedAnimationsAction(ids))
        dispatch(addObjectToSelectedObjectsAction({ id: objectId }))
    }

    const addSelectedAnimations = (ids: string[]) => {
        dispatch(addSelectedAnimationsAction(ids))
    }

    const removeSelectedAnimations = (ids: string[]) => {
        dispatch(removeSelectedAnimationsAction(ids))
    }

    /* HELPER HANDLERS */

    const handleActiveObjectWhenCreating = (id: string, objectId: string) => {
        dispatch(clearSelectedAnimationsAction())
        setActiveAnimations([id], objectId)
    }

    const addAnimation = (animation: AnimationI) => {
        dispatch(addAnimationAction({ animation: animation, objectId: animation.objectId }))
        handleActiveObjectWhenCreating(animation.id, animation.objectId)

        dispatch(updateMasterTimeline(timeToSeek))
    }

    const createSequenceAnimations = (
        animations: AnimationI[],
        object: AnySceneObjectT,
        dimensions: { width: number; height: number }
    ) => {
        dispatch(createSequenceAnimationsAction({ animations, object, dimensions }))
        setTimeout(() => dispatch(updateMasterTimeline(timeToSeek)), 500) // short delay to ensure that the objects are created in Canvas
    }
    const addAnimations = (animations: AnimationI[]) => {
        animations.forEach((animation: AnimationI) => {
            //breakpoint
            dispatch(addAnimationAction({ animation: animation, objectId: animation.objectId }))
            // handleAnimationIdsWhenCreating(animation.objectId, animation.id)
        })

        setTimeout(() => dispatch(updateMasterTimeline(timeToSeek)), 500) // short delay to ensure that the objects are created in Canvas
    }

    const addNewAnimation = (objectId: string, startOffset: number) =>
        addAnimation(createAnimation(objectId, startOffset, undefined))

    const duplicateAnimations = (animations: AnimationI[]) => {
        let temporaryDuplicatedAnimation: AnimationI[] = []

        animations.forEach((animation: AnimationI) => {
            temporaryDuplicatedAnimation.push(
                createAnimation(animation.objectId, animation.delay, {
                    ...animation,
                    title: animation.title + ' copy',
                })
            )
        })
        dispatch(duplicateAnimationsAction({ animations: temporaryDuplicatedAnimation }))
        dispatch(clearSelectedAnimationsAction())
        temporaryDuplicatedAnimation.forEach((newAnimation) => {
            dispatch(addSelectedAnimationsAction([newAnimation.id]))
            dispatch(addObjectToSelectedObjectsAction({ id: newAnimation.objectId }))
        })
        setTimeout(() => refreshMasterTimeline(), 500)
    }
    const handleRefresh = () => {
        dispatch(refreshAllStyles())
        dispatch(updateMasterTimeline(timeToSeek))
    }

    const updateAnimation = (id: string, objectId: string, property: any, value: any) => {
        dispatch(
            updateObjectAnimationAction(
                {
                    id: id,
                    objectId: objectId,
                    property: property,
                    value: value,
                },
                handleRefresh
            )
        )
    }

    const updateAnimationTweenState = (
        animation: AnimationI,
        state: 'from' | 'to',
        property: string,
        value: any
    ) => {
        const updatedStateObject: SimpleStyleT[] = animation.tween[state].map((stateObject) => {
            if (stateObject.property === property) return { ...stateObject, value: value }
            else return stateObject
        })

        const updatedTween: TweenT = {
            ...animation.tween,
            [state]: updatedStateObject,
        }
        updateAnimation(animation.id, animation.objectId, 'tween', updatedTween)
    }

    /**
     * delete selected animations and clear activeAnimations
     * @param animations
     */
    const deleteAnimations = (animations: AnimationI[]) => {
        dispatch(deleteAnimationsAction({ animations }))

        //clear state.activeAnimation (value, selected) //to do -refactor only keep selected
        dispatch(clearSelectedAnimationsAction())

        //refresh styles and set timeToSeek
        dispatch(refreshAllStyles())
        dispatch(updateMasterTimeline(timeToSeek))
    }

    return {
        addNewAnimation,
        addAnimation,
        addAnimations,
        createSequenceAnimations,
        duplicateAnimations,
        updateAnimation,
        updateAnimationTweenState,
        deleteAnimations,
        setActiveAnimations,
        addSelectedAnimations,
        removeSelectedAnimations,
    }
}
