export const compareAnimations = (
    animation1: AnimationI | undefined,
    animation2: AnimationI | undefined
) => {
    if (animation1 && animation2) {
        if (animation1.tween.name < animation2.tween.name) {
            return -1
        }
        if (animation1.tween.name > animation2.tween.name) {
            return 1
        }
        return animation1.delay - animation2.delay
    }
    return 0
}
