import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import { KeyboardEvent } from 'react'
import { useController } from '../../../../hooks/useController'
import useFramerate from '../../../../hooks/useFramerate'
import useKeyDown from '../../../../hooks/useKeyDown'

interface TimingProps {
    time: number
    name: string
    label: string
    unit: string
    handleOnChange: (e: any) => void
}

export const TimingInput = ({ time, name, label, unit, handleOnChange }: TimingProps) => {
    const { resultTimeFramerate } = useFramerate()
    const { value, onChange } = useController(resultTimeFramerate(time).toString())
    const { handleOnEnterKeyDown } = useKeyDown()

    return (
        <TextField
            fullWidth
            error={+time < 0 ? true : false}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={handleOnChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                handleOnEnterKeyDown(e, handleOnChange)
            }
            InputProps={{
                inputProps: { min: -99999, max: 99999, step: 1 },
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
        />
    )
}

export const TimingOffsetInput = ({ time, name, label, unit, handleOnChange }: TimingProps) => {
    const { value, onChange } = useController(time.toString())

    const handleOnBlur = (e: any): void => {
        handleOnChange(e)
        e.target.value = 0
        onChange(e)
    }
    const handleEnterKey = (e: any) => {
        if (e.key === 'Enter') {
            handleOnChange(e)
            e.target.value = 0
            onChange(e)
        }
    }

    return (
        <TextField
            fullWidth
            error={+time < 0 ? true : false}
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            onBlur={handleOnBlur}
            onKeyDown={handleEnterKey}
            InputProps={{
                inputProps: { min: -99999, max: 99999, step: 1 },
                endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
        />
    )
}
