import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { objectNodeUpdate } from './objectNodeUpdate'
import { UpdateObjectsDifferentStyles } from './objectTypes'

export const updateObjectsDifferentStylesProperty = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateObjectsDifferentStyles[]>
) => {
    const temporaryObjects = action.payload
    temporaryObjects.forEach((obj) => {
        const object = obj.object
        const property = obj.property
        const value = obj.value
        const style: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === property
        )

        if (!style) return
        if (style.value === value && style.property === property) {
            return
        } else {
            const restStyles: SimpleStyleT[] = object.styles.filter(
                (style) => style.property !== property
            )
            const updatedStyles: SimpleStyleT[] = [
                ...restStyles,
                {
                    ...style,
                    value: value,
                },
            ]
            const id = object.id
            state.value = {
                ...state.value,
                [id]: objectNodeUpdate(state.value[id], {
                    id: id,
                    property: 'styles',
                    value: updatedStyles,
                }),
            }
        }
    })
}
