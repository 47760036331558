import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { updateObjectStyles } from '../object/updateObjectStyles'
import { CreateSequenceAnimationsT } from './animationTypes'

const processAnimation = (animations: AnimationI[]): Record<string, AnimationI | undefined> => {
    let recordAnimations: Record<string, AnimationI | undefined> = {}
    animations.forEach((animation) => {
        recordAnimations[animation.id] = animation
    })
    return recordAnimations
}

export const createSequenceAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<CreateSequenceAnimationsT>
) => {
    const object = action.payload.object
    const parentId = action.payload.object.parentId
    const recordAnimations = processAnimation(action.payload.animations)
    const dimensions = action.payload.dimensions
    const newStyles = updateObjectStyles(object, dimensions)
    if (parentId) {
        state.value = {
            ...state.value,
            [object.id]: {
                ...object,
                styles: newStyles,
                animations: recordAnimations,
            },
            [parentId]: {
                ...state.value[parentId],
                childIds: [...state.value[parentId].childIds, object.id],
            },
        }
    } else {
        state.value = {
            ...state.value,
            [object.id]: {
                ...object,
                ...state.value[object.id],
                styles: newStyles,
                animations: recordAnimations,
            },
        }
    }
}
