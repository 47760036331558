import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'
import { animationNode } from './animationNode'
import { UpdateOffsetAnimationsT } from './animationTypes'

export const moveOffsetAnimationsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<UpdateOffsetAnimationsT>
) => {
    const selectedAnimations = action.payload.selectedAnimations
    const offset = action.payload.offset
    selectedAnimations.forEach((animation: AnimationI) => {
        const objectId = animation.objectId
        state.value = {
            ...state.value,
            [objectId]: {
                ...state.value[objectId],
                animations: {
                    ...state.value[objectId].animations,
                    [animation.id]: animationNode(
                        state.value[objectId].animations[animation.id] ?? ({} as AnimationI),
                        {
                            property: 'delay',
                            value: +animation!.delay + +offset,
                        }
                    ),
                },
            },
        }
    })
}
